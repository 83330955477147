import { AxiosInstance, AxiosResponse } from 'axios';
import { Obj, IAPIResponse } from '../types/interfaces';

interface IRenewalService {
      getExpiryDateReports: (

   
    from?:string|Date|null,
    to?:string|Date|null,
    dayCount?:number| string,
    type?:string ,
    companyIds?:string[]
  ) => Promise<IAPIResponse<Obj>>;

      getRenewalDataReports: (
    rows: number,
    page: number,
    sortBy:string,
    orderBy:string,
   
    from?:string,
    to?:string,
    dayCount?:number| string,
       companyIds?:string[],
       type?:string,
       hideOnGoingRenewals?:boolean,
  ) => Promise<IAPIResponse<Obj>>;
   downloadRenewalDataReports: (
    from?:string,
    to?:string,
    dayCount?:number| string,
       companyIds?:string[],
       type?:string,
       hideOnGoingRenewals?:boolean,
  ) => Promise<IAPIResponse<Obj>>;
        sendRenewalEmailNotification: (
userId:string,
type:string,
date:string,
renewalId:string,
renewalType:string,

  ) => Promise<IAPIResponse<Obj>>;
  createService: (data: Obj,reminderId?:string,reminderType?:string) => Promise<IAPIResponse>;

}
const renewalServices = (httpClient: AxiosInstance): IRenewalService  => {
  return {
    getExpiryDateReports: async  (from,to,dayCount,type,companyIds) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/renewals?from=${from}&to=${to}&dayCounts=${dayCount}&type=${type}`,{companyIds}
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    getRenewalDataReports: async  (rows,page,sortBy,orderBy,from,to,dayCount,companyIds,type,hideOnGoingRenewals) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/renewalTable?page=${page}&rows=${rows}&sortBy=${sortBy}&orderBy=${orderBy}&from=${from}&to=${to}&dayCounts=${dayCount}&type=${type}`,
          {    companyIds,
            hideOnGoingRenewals
          }
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    downloadRenewalDataReports: async  (from,to,dayCount,companyIds,type,hideOnGoingRenewals) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/renewalTable/download?from=${from}&to=${to}&dayCounts=${dayCount}&type=${type}`,
          {    companyIds,
            hideOnGoingRenewals
          }
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    sendRenewalEmailNotification: async  (userId,type,date,renewalId,renewalType) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/company/renewalNotification',
          {
            userId,type,date,renewalId,renewalType
          }
       
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    createService: async (data,reminderId,reminderType): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/create/service/client?reminderId=${reminderId}&reminderType=${reminderType}`,
          data
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to create service.' };
      }
    },
  };
};

export default renewalServices;
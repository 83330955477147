import React, { ReactNode } from 'react';
import { FormControl, FormHelperText, InputLabel, Select, SelectProps, useTheme } from '@mui/material';

interface SelectCurvedOutlineProps extends SelectProps {
    children: ReactNode;
}

const SelectCurvedOutline = ({ children, label, ...props }: SelectCurvedOutlineProps) => {
  const theme = useTheme();

  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        sx={{
          borderRadius: '9px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors?.primary.default}`
          }
        }} 
        {...props}
      >
        {children}
      </Select>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectCurvedOutline;
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as svg } from './ReplyIcon.svg';

const ReplyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      component={svg}
      viewBox='0 0 21 20'
      sx={{
        margin: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // '& path': {
        //   fill: theme.colors?.buttonIcon.default
        // },
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default ReplyIcon;
import React, { FC, useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import ReplyIcon from '../icons/reply-icon/ReplyIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { inbox } from '../../service';
import { Obj } from '../../types/interfaces';
import MessageAccordion from './message-accordion/MessageAccordion';
import { ModalComponent } from '../common/modal/ModalComponent';
import ReplyMessage from '../inbox/Messages/ReplyMessage';
import { useAppState } from '../../state/context/AppStateContext';

interface ReadMessageProps {}

const ReadMessage: FC<ReadMessageProps> = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar: snackBar } = useSnackbar();
  const [data, setData] = useState<Obj>({});
  const [expanded, setExpanded] = React.useState<number | false>(0);
  const [isOpen, setIsOpen] = useState(false);
  const { SetMessageTrigger, setNewInboxCount } = useAppState();

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleMessage = () => {
    if (!params.id) {
      navigate('/inbox');
      snackBar('Message not found.', { variant: 'error' });
      return;
    }

    inbox.message(params.id).then((res) => {
      if (!res.status) {
        snackBar(res.message, { variant: 'error' });
        return;
      }

      if (res.data) {
     

        setData(res.data);
       
      }
    });
  };

  useEffect(() => {
  
    handleMessage();
  }, [params.id]);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleReplyMessage = (data: { message: string }) => {
    if (!params.id) {
      closeModal();
      snackBar('Message not found.', { variant: 'error' });
      return;
    }

    inbox.reply(params.id, data).then((res) => {
      if (!res.status) {
        snackBar(res.message, { variant: 'error' });
        return;
      }

      closeModal();
      handleMessage();
      snackBar(res.message, { variant: 'success' });
    });
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        gap={1}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack color='primary' fontSize={'medium'} />
        </IconButton>
        <Typography
          color='primary'
          fontWeight={800}
          fontSize={theme.fontSizes.h3}
        >
          {data.subject}
        </Typography>
      </Box>
      <MessageAccordion
        data={data}
        expanded={expanded}
        onChange={handleChange}
        index={0}
      />

      {data?.replyMessages?.map((item: Obj, index: number) => (
        <MessageAccordion
          key={index}
          data={item}
          expanded={expanded}
          onChange={handleChange}
          index={index + 1}
        />
      ))}

      <Box
        display='flex'
        justifyContent='flex-start'
        sx={{ pl: { xs: 0, sm: 10 } }}
      >
        <Button
          variant='contained'
          sx={{
            textTransform: 'none',
            px: 5,
            py: 2,
            borderRadius: '9px',
            fontSize: theme.fontSizes.medium,
          }}
          startIcon={<ReplyIcon />}
          onClick={() => setIsOpen(true)}
        >
          Reply
        </Button>
      </Box>
      <ModalComponent isOpen={isOpen} isClose={closeModal}>
        <ReplyMessage onClose={closeModal} onCompose={handleReplyMessage} />
      </ModalComponent>
    </Box>
  );
};

export default ReadMessage;

import {
  Box,
  Card,
  Paper,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, ReactNode } from 'react';

interface ICardWrapper {
  children: ReactNode;
  sx?: SxProps;
}

const CardWrapper: FC<ICardWrapper> = ({ children, sx }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        p: {xs:1,sm:2},
      
        borderRadius: 3,
        boxShadow: theme.colors?.shadow.default,
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};

export default CardWrapper;

import { AxiosInstance, AxiosResponse } from 'axios';
import { IAPIResponse, IInboxCreate, Obj } from '../types/interfaces';

interface IInboxService {
    compose: (data: IInboxCreate) => Promise<IAPIResponse>;
    messages: (
      rows: number,
      page: number,
      search?: string,
      filter?: string,
    ) => Promise<IAPIResponse<{ count: number; inboxMessages: Obj[] }>>;
    sentMessages: (rows: number, page: number, search: string) => Promise<IAPIResponse>;
    message: (id: string) => Promise<IAPIResponse>;
    reply: (id: string, data: { message: string }) => Promise<IAPIResponse>;
    updateRead: (id: string) => Promise<IAPIResponse>;
    delete: (id: string) => Promise<IAPIResponse>;
}

const inboxService = (httpClient: AxiosInstance): IInboxService => {
  return {
    compose: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post('/app/user/create/message', data);
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to compose message.' };
      }
    },
    messages: async (rows, page, search, filter): Promise<IAPIResponse<{ count: number; inboxMessages: Obj[] }>> => {
      try {
        const res: AxiosResponse = await httpClient.get(`/app/user/inbox/message/list?rows=${rows}&page=${page}&search=${search}&filter=${filter}`);
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get messages.' };
      }
    },
    sentMessages: async (rows, page, search): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(`/app/user/sent/message/list?page=${page}&rows=${rows}&search=${search}`);
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get messages.' };
      }
    },
    message: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(`/app/user/message/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get message.' };
      }
    },
    reply: async (id, data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(`/app/user/message/reply/update/${id}`, data);
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to send reply message.' };
      }
    },
    updateRead: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(`/admin/agency/message/update/read/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to update message.' };
      }
    },
    delete: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/delete/message/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to delete message.' };
      }
    },
  };
};

export default inboxService;
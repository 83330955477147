import { useState } from 'react';
import { createPortal } from 'react-dom';
import ModalContent from './ModalContent';
interface INotificationDataProps {
  notificationData: {
    title: string;
    message: string;
  };
}
const PortalExample: React.FC<INotificationDataProps> = ({notificationData}) => {
  const [showModal, setShowModal] = useState(true);
  const mainBody = document.getElementById('main-body') ?? document.body;
  return (
    <>
      {showModal &&
        createPortal(
          <ModalContent notificationData={notificationData} />,
          document.body
        )}
    </>
  );
};
export default PortalExample;

import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import CardWrapper from './Wrapper/CardWrapper';

function Loader() {
  return (
    <CardWrapper>
      <Box
        minWidth={'100%'}
        minHeight={'100vh'}
        display='flex'
        alignItems={'center'}
        justifyContent={'center'}
      >
        <CircularProgress />
      </Box>
    </CardWrapper>
  );
}

export default Loader;

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useMediaQuery } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  boxShadow: '0px 10px 73.46px rgba(41, 72, 152, 0.15)',
  overflowY: 'auto',
  borderRadius: '25px',
  outline: 'none',
  border: 'none',
};

interface IModal {
  children?: React.ReactNode;
  isOpen: boolean;
  isClose: any;
}
export const ModalComponent: React.FC<IModal> = ({
  isOpen,
  isClose,
  children,
}) => {
  const mobile = useMediaQuery('(max-width:500px)');
  return (
    <Box sx={{ border: 'hidden' }}>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={isOpen}
        onClose={isClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Set a transparent background color
            },
            timeout: 500,
          },
        }}
        // sx={{ border: 'hidden', '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
      >
        <Box sx={{ ...style, p: mobile ? 3 : 4 }}>{children}</Box>
      </Modal>
    </Box>
  );
};

import React, { useState, ReactNode, FC } from 'react';
import TableDataContext, { ITableDataContext } from '../context/TableDataContext';


const TableDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // companyTable Data
  const [companyPage, setCompanyPage] = useState(0);
  const [companyRecordsPerPage, setCompanyRecordsPerPage] = useState(15);
  const [companySortBy, setCompanySortBy] = useState('');
  const [companyAsc, setCompanyAsc] = useState(true);
  const [companySearch, setCompanySearch] = useState('');
  const [companyFilterValue, setCompanyFilterValue] = useState<number>(0);
  const [companyTypeData, setCompanyTypeData] = useState('all');


  //   EmployeeTable Data
  const [employeePage, setEmployeePage] = useState(0);
  const [employeeRecordsPerPage, setEmployeeRecordsPerPage] = useState(15);
  const [employeeSortBy, setEmployeeSortBy] = useState('');
  const [employeeAsc, setEmployeeAsc] = useState(true);
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [employeeFilterValue, setEmployeeFilterValue] = useState<number>(0);

  const contextValue: ITableDataContext= {
    companyPage, setCompanyPage,
    companyRecordsPerPage, setCompanyRecordsPerPage,
    companySortBy, setCompanySortBy,
    companyAsc, setCompanyAsc,
    companySearch, setCompanySearch,
    companyFilterValue, setCompanyFilterValue,
    companyTypeData, setCompanyTypeData,
    employeePage, setEmployeePage,
    employeeRecordsPerPage, setEmployeeRecordsPerPage,
    employeeSortBy, setEmployeeSortBy,
    employeeAsc, setEmployeeAsc,
    employeeSearch, setEmployeeSearch,
    employeeFilterValue, setEmployeeFilterValue
  };

  return (
    <TableDataContext.Provider value={contextValue}>
      {children}
    </TableDataContext.Provider>
  );
};

export default TableDataProvider;

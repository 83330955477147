import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { useAppState } from '../state/context/AppStateContext';
import { ISideDrawer } from '../types/interfaces';
import PaperIcon from './icons/paperIcon/PaperIcon';
import BagIcon from './icons/bagIcon/BagIcon';
import UserIcon from './icons/userIcon/UserIcon';
import SettingIcon from './icons/settingIcon/SettingIcon';
import ResourcesIcon from './icons/resourcesIcon/ResourcesIcon';
import LogoutIcon from './icons/logoutIcon/LogoutIcon';
import ReportIcon from './icons/reportIcon/ReportIcon';
import { useNavigate } from 'react-router-dom';
import { getSideBarState, removeAuthStorage, setSideBarState } from '../utils';
import MenuIcon from './icons/menuIcon/MenuIcon';
import UserProfile from './icons/UserProfile/UserProfile';
import InboxIcon from './icons/inbox/InboxIcon';
import HomeIcon from './icons/home/HomeIcon';
import ConfirmModal from './common/modal/ConfirmModal';
import { ModalComponent } from './common/modal/ModalComponent';

interface SideBarProps {
  children?: React.ReactNode;
  SetOpenSide?: React.Dispatch<React.SetStateAction<boolean>>;
}

  interface SidebarItem  {
  title: string;
  icon: JSX.Element;
  link?: string;
}
const SideBarForMobile: React.FC<SideBarProps> = ({
  children,
  SetOpenSide,
}) => {
  const theme = useTheme();
  const { authStatus, setAuthStatus } = useAppState();

  const [selectedTab, setSelected] = React.useState(0);
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    const SideBarState = getSideBarState();

    if (SideBarState) {
      setSelected(SideBarState.state);
    }
  }, []);
  const sideBarConstants:Record<string, SidebarItem>={
    Compliance: { title: 'Compliance', icon: <PaperIcon fontSize='large' /> },
    Company: { title: 'Company', icon: <BagIcon fontSize='large' />, link: '/company' },
    Employees: {
      title: 'Employees',
      icon: (
        <UserIcon
          fontSize='large'
          sx={{ color: theme.colors?.secondary.default, borderRadius: '100%' }}
        />
      ),
      link: '/employee',
    },
    Services:{
      title: 'Services',
      icon: <MenuIcon fontSize='large' />,
      link: '/services',
    },
    Messages: { title: 'Messages', icon: <InboxIcon fontSize='large' />, link: '/messages' },
    Reports:{ title: 'Reports', icon: <ReportIcon fontSize='large' />,link:'/reports' },
    Settings: {
      title: 'Settings',
      icon: <SettingIcon fontSize='large' />,
      link: '/settings',
    },
    Resources:  { title: 'Resources', icon: <ResourcesIcon fontSize='large' />,link:'/resources' },
    Dashboard:  {
      title: 'Dashboard',
      icon: <HomeIcon fontSize='large' />,
      link: '/dashboard',
    },
    Profile:{
      title: 'Profile',
      icon: (
        <UserProfile
          fontSize='large'
          sx={{ color: theme.colors?.secondary.default, borderRadius: '100%' }}
        />
      ),
      link: '/employee-Details',
    },
    Matters:     {
      title: 'Matters',
      icon: <HomeIcon fontSize='large' />,
      link: '/dashboard',
    },

  };

  const sideApp : Array<string> = authStatus?.roleSettings?.viewReports ?
    ['Compliance','Company','Employees','Services','Messages','Reports','Settings','Resources'] :
    ['Compliance','Company','Employees','Services','Messages','Settings','Resources'];
  
  const empApp: Array<string> =['Dashboard','Profile','Messages','Settings','Resources',];

  const clientApp: Array<string> =   ['Matters','Messages','Settings',];

  const getCall = (index: number) => {
    // SetOpenSide && SetOpenSide((prev) => !prev);
    setSelected(index);
    setSideBarState(index);
  };
  const Logout = () => {
    const logout: boolean = removeAuthStorage();
    if (logout) {
      setAuthStatus && setAuthStatus(undefined);

      navigate('/login');
    }
  };
  const handleCloseConfirm = () => {
    setConfirmModal(false);
  };
  const handleOpen = () => {
    setConfirmModal(true);
  };
  return (
    <Box
      sx={{
        // position: 'static',
        height: '80vh',

        p: 3,
        mt: 2,
        mb: 2,
        borderRadius: '10px',
        background: theme.colors?.primary.default,
        transition: '.5s',
        overflowY: 'auto',
        overflowX: 'hidden',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
      }}
    >
      <CssBaseline />

      <Grid
        container
        direction='column'
        rowGap={4}
        justifyContent='center'
        alignItems='center'
      >
        {(authStatus?.role === 'business_employee'
          ? empApp
          : authStatus?.role === 'client'
            ? clientApp
            : sideApp
        )?.map((key, index) => {
          const item=sideBarConstants[key];
          return (
            <Tooltip title={item.title} key={index}>
              <Grid item sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    // setOpen(false);

                    navigate(item?.link ?? '/');
                    getCall(index);
                  }}
                  // width={'400px'}
                >
                  <Box
                    sx={{
                      borderBottom:
                        selectedTab === index
                          ? `3px solid ${theme.colors?.warning.default}`
                          : '',

                      mr: 3,
                    }}
                  >
                    <Typography align='right'>{item.icon}</Typography>
                  </Box>

                  <Typography
                    color='secondary'
                    fontSize={'medium'}
                    align='left'
                    minWidth={'200px'}
                  >
                    {' '}
                    {item.title}
                  </Typography>
                </Box>
              </Grid>
            </Tooltip>
          );
        })}
      </Grid>

      <Grid container rowGap={4} justifyContent='center' alignItems='center'>
        <Tooltip title='Logout'>
          <Grid item sm={12}>
            <Box
              sx={{
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleOpen}
            >
              <Box
                sx={{
                  mr: 3,
                }}
              >
                <Typography align='right'>
                  {' '}
                  <LogoutIcon fontSize='large' />
                </Typography>
              </Box>

              <Typography
                color='secondary'
                fontSize={'medium'}
                align='left'
                minWidth={'200px'}
              >
                {' '}
                Log out
              </Typography>
            </Box>
          </Grid>
        </Tooltip>
      </Grid>
      <ModalComponent isOpen={confirmModal} isClose={handleCloseConfirm}>
        <ConfirmModal
          onSubmit={() => {
            Logout();
          }}
          isClose={handleCloseConfirm}
          message='Are you sure you want to logout?'
          ButtonText='Logout'
        />
      </ModalComponent>
    </Box>
  );
};

export default SideBarForMobile;

import { Box } from '@mui/material';

import SignUpText from '../../icons/signUpText/SignUpText';
import Img1 from '../../../assets/Img1.png';
import Img2 from '../../../assets/Img2.png';
import Img3 from '../../../assets/Img3.png';
import Img4 from '../../../assets/Img4.png';
import Img5 from '../../../assets/Img5.png';
import { useMemo } from 'react';

const SignUpImage = () => {
  const array=[Img1,Img2,Img3,Img4,Img5];
  const random= useMemo(() => (array[Math.floor(Math.random() * 5)]), []);
  return (
    <Box
      sx={{
        background: `url(${random})`,
        backgroundSize: 'cover',
        width: '100%',
        minHeight: '100%',
        maxHeight:'100%',
        backgroundRepeat: 'no-repeat',
              
             
               
      }}
    >
      <Box width={'90%'} margin={'auto'} paddingTop={7} >
        <SignUpText/>
      </Box>
    </Box>
  );
};

export default SignUpImage;
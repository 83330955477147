import { lazy } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import PageWrapper from '../components/PageWrapper';
import ChangeEmail from '../pages/ChangeEmail';
import PrivateRoute from './private-routes';

const SignIn = lazy(() => import('../pages/SignIn'));
const LogIn = lazy(() => import('../pages/Login'));
const Home = lazy(() => import('../pages/Home'));
const FourNotFour = lazy(() => import('../pages/404'));
const Company = lazy(() => import('../pages/Company'));
const Employee = lazy(() => import('../pages/Employee'));
const EmployeeList = lazy(() => import('../pages/EmployeeDetails'));
const Settings = lazy(() => import('../pages/Settings'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ForgetPassword = lazy(() => import('../pages/ForgetPassword'));
const Resources = lazy(() => import('../pages/Resources'));
const Reports = lazy(() => import('../pages/Reports'));
const OnBoardingPage = lazy(() => import('../pages/OnBoardingPage'));
const OnBoardingEditPage = lazy(() => import('../pages/OnBoardEditPage'));
const OnBoardingHistory = lazy(() => import('../pages/OnBoardingHistory'));

const Mfa = lazy(() => import('../pages/Mfa'));
const BusinessRelatedReporting = lazy(
  () => import('../pages/BusinessRelatedReporting')
);
const Services = lazy(() => import('../pages/Services'));
const EmployeeRelatedProblem = lazy(
  () => import('../pages/EmployeeRelatedReporting')
);
const EmployeeDashboard = lazy(() => import('../pages/EmployeeDashboard'));
const Dashboard = lazy(() => import('../pages/DashboardPage'));
const MessagesPage = lazy(() => import('../pages/MessagePage'));
const RecruitmentHistory = lazy(
  () => import('../pages/RecruitmentHistoryPage')
);
const Renewal = lazy(() => import('../pages/RenewalDashboardPage'));
import NotificationPage from '../pages/Notification';
import Inbox from '../pages/Inbox';
import Message from '../pages/Message';
export const UnAuthenticatedRoutes = {
  login: '/login',
  mfa: '/login/mfa',
  forgotPassword: '/forgot-password',
  resetPassword: '/verify/:token',
  signUp: '/account/setup/:token',
  changeEmail: '/change/mail/:token',
};

const route = [
  {
    link: '/',
    element: Home,
  },
  {
    link: '/company',
    element: Company,
  },
  {
    link: '/dashboard',
    element: EmployeeDashboard,
  },
  {
    link: '/multi-dashboard',
    element: Dashboard,
  },
  {
    link: '/employee',
    element: Employee,
  },
  {
    link: '/employee-Details/:id',
    element: EmployeeList,
  },
  {
    link: '/employee-Details',
    element: EmployeeList,
  },
  {
    link: '/services',
    element: Services,
  },
  {
    link: '/settings',
    element: Settings,
  },
  {
    link: '/inbox',
    element: Inbox,
  },
  {
    link: '/business-related-reporting',
    element: BusinessRelatedReporting,
  },
  {
    link: '/employee-related-reporting/:id',
    element: EmployeeRelatedProblem,
  },
  {
    link: '/company/home-office-reporting/:type/:tab',
    element: Company,
  },
  {
    link: '/company/:type',
    element: Company,
  },
  {
    link: '/employee-Details/home-office-reporting/:id/:type/:tab',
    element: EmployeeList,
  },
  {
    link: '/employee/:type',
    element: EmployeeList,
  },
  {
    link: '/notification',
    element: NotificationPage,
  },
  {
    link: '/message/:id',
    element: Message,
  },
  {
    link: '/messages',
    element: MessagesPage,
  },
  {
    link: '/recruitment-history',
    element: RecruitmentHistory,
  },
  {
    link: '/resources',
    element: Resources,
  },
  {
    link: '/reports',
    element: Reports,
  },
];

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={UnAuthenticatedRoutes.login} element={<LogIn />} />
      <Route path={UnAuthenticatedRoutes.mfa} element={<Mfa />} />
      <Route path={UnAuthenticatedRoutes.signUp} element={<SignIn />} />
      <Route
        path={UnAuthenticatedRoutes.forgotPassword}
        element={<ForgetPassword />}
      />
      <Route
        path={UnAuthenticatedRoutes.resetPassword}
        element={<ResetPassword />}
      />
      <Route
        path={UnAuthenticatedRoutes.changeEmail}
        element={<ChangeEmail />}
      />
      <Route element={<PageWrapper />}>
        {route.map((item, index) => (
          <Route
            key={index}
            path={item.link}
            element={<PrivateRoute component={item.element} />}
          />
        ))}
        {/* <Route
          path={'/dashboard'}
          element={<PrivateRoute component={EmployeeDashboard} />}
        />
        <Route path={'/company'} element={<Company />} />
        <Route path={'/employee'} element={<Employee />} />
        <Route path={'/employee-Details/:id'} element={<EmployeeList />} />
        <Route path={'/employee-Details'} element={<EmployeeList />} />
        <Route path={'/services'} element={<Services />} />
        <Route path={'/settings'} element={<Settings />} />
        <Route path={'/inbox'} element={<Inbox />} />
        <Route
          path={'/message/:id'}
          element={<PrivateRoute component={Message} />}
        />
        <Route
          path={'/business-related-reporting'}
          element={<BusinessRelatedReporting />}
        />
        <Route
          path={'/employee-related-reporting/:id'}
          element={<EmployeeRelatedProblem />}
        />
        <Route
          path={'/company/home-office-reporting/:type/:tab'}
          element={<Company />}
        />
        <Route
          path={'/employee-Details/home-office-reporting/:id/:type/:tab'}
          element={<EmployeeList />}
        />
        <Route path={'/'} element={<Home />} /> */}
        <Route
          path={'/onboarding/:id'}
          element={<PrivateRoute component={OnBoardingPage} />}
        />
        <Route
          path={'/onboarding/edit/:id'}
          element={<PrivateRoute component={OnBoardingEditPage} />}
        />
        <Route
          path={'/onboarding/history/:id'}
          element={<PrivateRoute component={OnBoardingHistory} />}
        />
        <Route
          path={'/renewal'}
          element={<PrivateRoute component={Renewal} />}
        />
        <Route path={'*'} element={<FourNotFour />} />
      </Route>
    </>
  )
);

export default router;

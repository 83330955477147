import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import logo from '../../../assets/Success.svg';
import CustomButton from '../custom-button/CustomButton';
import { useNavigate } from 'react-router-dom';

interface SuccessProps {
  title: string;
  content: string;
  isLogin?: boolean;
}

const Success: FC<SuccessProps> = ({ title, content, isLogin }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  return (
    <Box width='100%'>
      <Box width='100%' mb={3}>
        <img width={'122px'} height={'122px'} src={logo} />
      </Box>
      <Typography
        fontSize={theme.fontSizes.h2}
        fontWeight={600}
        color='#2B4156'
        textTransform={'uppercase'}
        mb={2}
      >
        {title}
      </Typography>
      <Typography
        fontSize={theme.fontSizes.medium}
        color={theme.colors?.black}
      >
        {content}
      </Typography>
      { isLogin ? (
        <>
          <Typography
            fontSize={theme.fontSizes.medium}
            color={theme.colors?.black}
          >
            Click below to log in magically.
          </Typography>
          <Box sx={{ mt: 2 }} width='100%' >
            <CustomButton
              type='submit'
              fullWidth
              variant='contained'
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.colors?.buttonBackground.default,
              }}
              onClick={() => navigate('/login')}
            >
              Continue
            </CustomButton>
          </Box>
        </>
      ) : (
        <>
          <Typography
            fontSize={theme.fontSizes.medium}
            color={theme.colors?.black}
          >
            Click below to back to portal.
          </Typography>
          <Box sx={{ mt: 2 }} width='100%' >
            <CustomButton
              type='submit'
              fullWidth
              variant='contained'
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.colors?.buttonBackground.default,
              }}
              onClick={() => navigate('/')}
            >
              Continue
            </CustomButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Success;
import React from 'react';
import PortalExample from './Portal';
import { Box } from '@mui/material';
interface INotificationDataProps {
  notificationData: {
    title: string;
    message: string;
  };
}
const BroadCast: React.FC<INotificationDataProps> = ({ notificationData }) => {
  return (
    <Box sx={{ position: 'absolute', right: 0, top: 500 }}>
      <PortalExample notificationData={notificationData} />
    </Box>
  );
};

export default BroadCast;
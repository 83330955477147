import { Typography } from '@mui/material';
import React from 'react';

interface IHeading {
  children?: React.ReactNode;
}

const Heading: React.FC<IHeading> = ({ children }) => {
  return (
    <>
      <Typography
        color={'primary'}
        align='left'
        paddingLeft={2}
        fontWeight='700'
        fontSize={'25px'}
        letterSpacing='1px'
      >
        {' '}
        {children}
      </Typography>
    </>
  );
};

export default Heading;

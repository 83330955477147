import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { IAuth, Obj } from '../types/interfaces';

const KEY = 'sessionData';

export const isTokenExpired = (token?: string): boolean => {
  try {
    if (!token) {
      return true;
    }

    const decodedToken = <Obj>jwtDecode(token);
    const currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return true;
    }

    return false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

export const setAuthStorage = (auth: IAuth): void => {
  try {
    localStorage.setItem(KEY, JSON.stringify(auth));
    return;
  } catch (err) {
    return;
  }
};
export const setSideBarStatus = (isOpen: boolean | undefined): void => {
  try {
    localStorage.setItem('SideBarStatus', JSON.stringify(isOpen));
    return;
  } catch (err) {
    return;
  }
};

export const getSideBarStatus = () => {
  try {
    const isOpen = sessionStorage.getItem('SideBarStatus');

    if (!isOpen) {
      return;
    }

    const result = JSON.parse(isOpen);
    return result;
  } catch (err) {
    return;
  }
};
export const setSideBarState = (state: number): void => {
  try {
    sessionStorage.setItem('SideBarState', JSON.stringify({ state: state }));
    return;
  } catch (err) {
    return;
  }
};

export const getNotificationCount = (userId: string) => {
  try {
    const isCount = localStorage.getItem(`notificationCount${userId}`);

    if (!isCount) {
      return { count: 0 };
    }

    const result = JSON.parse(isCount);
    return result;
  } catch (err) {
    return;
  }
};
export const setNotificationCount = (state: number, userId: string): void => {
  try {
    localStorage.setItem(
      `notificationCount${userId}`,
      JSON.stringify({ count: state })
    );
    return;
  } catch (err) {
    return;
  }
};

export const getInboxCount = (userId: string) => {
  try {
    const isCount = localStorage.getItem(`inbox${userId}`);

    if (!isCount) {
      return { count: 0 };
    }

    const result = JSON.parse(isCount);
    return result;
  } catch (err) {
    return;
  }
};
export const setInboxCount = (state: number, userId: string): void => {
  try {
    localStorage.setItem(`inbox${userId}`, JSON.stringify({ count: state }));
    return;
  } catch (err) {
    return;
  }
};
export const getSideBarState = () => {
  try {
    const isOpen = sessionStorage.getItem('SideBarState');
    if (!isOpen) {
      return;
    }

    const result = JSON.parse(isOpen);
    return result;
  } catch (err) {
    return;
  }
};
export const removeAuthStorage = () => {
  try {
    sessionStorage.removeItem('SideBarState');
    localStorage.removeItem(KEY);
    return true;
  } catch (error) {
    return false;
  }
};
export const getAuthStorage = (): IAuth | undefined => {
  try {
    const auth = localStorage.getItem(KEY);
    if (!auth) {
      return;
    }

    const result = JSON.parse(auth);
    return result;
  } catch (err) {
    return;
  }
};

export const capitalize = (str: string): string => {
  if (!str || !str.trim()) {
    return str;
  }

  const s = str.toLowerCase().replace(/_/, ' ');
  return s
    .split(' ')
    .map((st) => st.charAt(0).toUpperCase() + st.slice(1))
    .join(' ');
};

export function clone<T = Obj>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export const formatDate = (
  date: string | Date,
  requiredString = false,
  requiredTime = false,
  format = ''
) => {
  const now = moment();
  if (format) {
    return moment(date).format(format);
  }
  if (requiredTime) {
    return moment(date).format('DD/MM/YYYY hh:mm:ss A');
  }

  if (requiredString) {
    const days = now.diff(date, 'days');

    switch (days) {
      case 0:
        return 'Today';

      default:
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }

  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
};
export const validateNumber = (e: string) => {
  if (!e) {
    return true;
  }
  const pattern = /^[0-9]$/;

  return pattern.test(e);
};

export const fileDownload = (
  data: Uint8Array,
  type: { type: string },
  fileName: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    const pdfBuffer = new Uint8Array(data);
    const url = window.URL.createObjectURL(new Blob([pdfBuffer], type));
    const link = document.createElement('a');
    const fileExtension = type.type.split('/')[1];
    link.id = 'file-download-link';
    link.href = url;
    link.setAttribute('download', `${fileName}.${fileExtension}`);
    document.body.appendChild(link);
    link.click();
    // Safe time to trigger click
    setTimeout(() => {
      link.remove();
      resolve(true);
    }, 1000);
  });
};

export const getDayAdjacent = (
  inputDate: Date,
  direction: 'before' | 'after',
  dayCount: number
): string => {
  const targetDate = new Date(inputDate);
  if (direction === 'before') {
    targetDate.setDate(targetDate.getDate() - dayCount);
  } else if (direction === 'after') {
    targetDate.setDate(targetDate.getDate() + dayCount);
  } else {
    throw new Error(
      'Invalid direction. Please specify either "before" or "after".'
    );
  }
  const year = targetDate.getFullYear();
  const month = targetDate.getMonth() + 1;
  const day = targetDate.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;
  return formattedDate;
};

import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { FC, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ITabheading } from '../../../types/interfaces';

interface IDropDownButton {
  tab: Array<ITabheading>;

  setValue: React.Dispatch<React.SetStateAction<number>>;
}
const DropDownButton: FC<IDropDownButton> = ({ tab, setValue }) => {
  const [name, setName] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (tab) {
      setName(tab[0].title);
    }
  }, []);
  return (
    <Box alignItems={'flex-start'} display={'flex'} sx={{paddingLeft:{sm:'40px'}}} >
      <Button
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant='contained'
      >
        {name}
      </Button>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {tab &&
          (tab || []).map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose();
                  setName(item.title);
                  setValue(index);
                }}
              >
                {item.title}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
};
export default DropDownButton;

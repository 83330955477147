import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as svg } from './BroadCastIcon.svg';

const BroadCastIcon = (props: SvgIconProps) => {
  const theme =useTheme();
  return (
    <SvgIcon
      component={svg}
      viewBox="0 0 44 44"
      sx={{
        margin: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
       
        // '& path': {
        //   fill: theme.colors?.primary.default
        // },
        ...props.sx,
      
      }}
      {...props}
    />
  );
};

export default BroadCastIcon;

import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { IInboxCreate } from '../../../types/interfaces';
import EditIcons from '../../icons/EditIcon/EditIcons';
import NewMessageIcon from '../../icons/NewMessageIcon/NewMessageIcon';

interface NewMessageProps {
  onClose: VoidFunction;
  onCompose: (data: IInboxCreate) => void;
}

const NewMessage: FC<NewMessageProps> = ({ onCompose, onClose }) => {
  const theme = useTheme();
  const { enqueueSnackbar: snackBar } = useSnackbar();
  const [data, setData] = useState<IInboxCreate>({
    subject: '',
    message: '',
  });
  const [dataError, setDataError] = useState({
    subject: '',
    message: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setDataError({ ...dataError, [name]: '' });
  };

  const validateField = () => {
    let valid = true;
    let errObj = dataError;

    if (!data.subject) {
      errObj = { ...errObj, subject: 'Required subject.' };
      valid = false;
    }

    if (!data.message) {
      errObj = { ...errObj, message: 'Required message.' };
      valid = false;
    }

    setDataError(errObj);
    return valid;
  };

  const handleSubmit = () => {
    if (!validateField()) {
      snackBar('Missing required fields', { variant: 'error' });
      return;
    }

    onCompose(data);
  };

  return (
    <Box>
      <Box sx={{ maxWidth: '818px', maxHeight: '80vh' }}>
        <Box
          sx={{
            width: { xs: '305px', sm: '540px', md: '600px' },
            minWidth: '305px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '63px',
              borderRadius: '10px',
              backgroundColor: theme.colors?.primary.default,
            }}
          >
            <Stack direction='row' alignItems='center' gap={1}>
              <NewMessageIcon sx={{ mr: 1 }} fontSize='large' />
              <Typography color={'#ffffff'} fontSize='20px' fontWeight={700}>
                New Message
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Stack
              direction='column'
              justifyContent='center'
              alignItems='start'
              spacing={2}
              marginTop={3}
            >
              {/* <TextField
                id='outlined-basic'
                label='To'
                variant='outlined'
                fullWidth
                name='userId'
                value={data.userId}
                onChange={(e) => handleChange(e)}
                error={!!dataError.userId}
                helperText={dataError.userId}
              /> */}
              <TextField
                id='outlined-basic'
                label='Subject'
                variant='outlined'
                fullWidth
                name='subject'
                value={data.subject}
                onChange={(e) => handleChange(e)}
                error={!!dataError.subject}
                helperText={dataError.subject}
              />
              <TextField
                id='outlined-multiline-static'
                multiline
                rows={4}
                // defaultValue='Default Value'
                label='Message'
                fullWidth
                name='message'
                value={data.message}
                onChange={(e) => handleChange(e)}
                error={!!dataError.message}
                helperText={dataError.message}
              />
            </Stack>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
              marginTop={5}
            >
              <Button
                variant='contained'
                fullWidth
                sx={{
                  backgroundColor: '#BFA980',
                 
                  height: '60px',
                 
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant='contained'
                fullWidth
                sx={{ height: '60px' }}
              >
                send
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewMessage;

import { AxiosResponse, AxiosInstance } from 'axios';
import { IAPIResponse, Obj } from '../types/interfaces';



interface IResourceService {
  getEmployeeReports: (
    rows: number,
    page: number,
    sortBy:string,
    orderBy:string,
    data:Obj,
  ) => Promise<IAPIResponse<Obj>>;
    getChangesReports: (
    rows: number,
    page: number,
    sortBy:string,
    orderBy:string,
    data:Obj,
    from?:string,
    to?:string,
  ) => Promise<IAPIResponse<Obj>>;
    getExpiryDateReports: (
    rows: number,
    page: number,
    sortBy:string,
    orderBy:string,
    data:Obj,
    from?:string,
    to?:string,
    dayCount?:number | string,
    isExpired?:boolean |string ,
  ) => Promise<IAPIResponse<Obj>>;
    downloadReports: (
    reportType:number,
    data:Obj,
  ) => Promise<IAPIResponse<Obj>>;
    downloadChangesReports: (
    reportType:number,
    data:Obj,
    from?:string,
    to?:string,
  ) => Promise<IAPIResponse<Obj>>;
    downloadExpiryReports: (
    reportType:number,
    data:Obj,
    from?:string,
    to?:string,
    dayCount?:number | string,
    isExpired?:boolean |string ,
  ) => Promise<IAPIResponse<Obj>>;
    getMonthlyCheckListReports: (
    rows: number,
    page: number,
    sortBy:string,
    orderBy:string,
    data:Obj,
  ) => Promise<IAPIResponse<Obj>>;
    downloadMonthlyCheckListReports: (
    reportType:number,
    data:Obj
  ) => Promise<IAPIResponse<Obj>>;

}

const reportService = (httpClient: AxiosInstance): IResourceService => {
  return {
    getEmployeeReports: async  (rows,page,sortBy,orderBy,data) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/employee/reports?page=${page}&rows=${rows}&sortBy=${sortBy}&orderBy=${orderBy}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message:'Something Went Wrong.'};
            
      }
    },
    getChangesReports: async  (rows,page,sortBy,orderBy,data,from,to) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/company-changes/reports?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&from=${from}&to=${to}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    getExpiryDateReports: async  (rows,page,sortBy,orderBy,data,from,to,dayCount,isExpired) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/expiry-date/reports?page=${page}&rows=${rows}&sortBy=${sortBy}&orderBy=${orderBy}&from=${from}&to=${to}&dayCounts=${dayCount}&alreadyExpired=${isExpired}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    downloadReports: async  (reportType,data) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/employee/download-report?&reportType=${reportType}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    downloadChangesReports: async  (reportType,data,from,to) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/changes/download-report?&reportType=${reportType}&from=${from}&to=${to}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    downloadExpiryReports: async  (reportType,data,from,to,dayCount,isExpired) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/expiry/download-report?&reportType=${reportType}&from=${from}&to=${to}&dayCounts=${dayCount}&alreadyExpired=${isExpired}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    getMonthlyCheckListReports: async  (rows,page,sortBy,orderBy,data) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/monthly-check/reports?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    downloadMonthlyCheckListReports: async  (reportType,data) : Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/monthly-check/download-report?&reportType=${reportType}`,
          data
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },

    
  };
};

export default reportService;

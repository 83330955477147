import React, { FC, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ReplyIcon from '../../icons/reply-icon/ReplyIcon';
  
interface ReplyMessageProps {
  onClose: VoidFunction;
  onCompose: (data: { message: string }) => void;
}
  
const ReplyMessage: FC<ReplyMessageProps> = ({ onCompose, onClose }) => {
  const theme = useTheme();
  const { enqueueSnackbar: snackBar } = useSnackbar();
  const [data, setData] = useState<{ message: string }>({
    message: '',
  });
  const [dataError, setDataError] = useState({
    message: '',
  });
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (value.length > 500) {
      setDataError({ ...dataError, [name]: 'Exceeded 500 characters.' });
      return;
    }

    setDataError({ ...dataError, [name]: '' });
  };
  
  const validateField = () => {
    let valid = true;
    let errObj = dataError;
  
    if (!data.message) {
      errObj = { ...errObj, message: 'Required message.' };
      valid = false;
    } else if (data.message.length > 500) {
      errObj = { ...errObj, message: 'Exceeded 500 characters.' };
      valid = false;
    }
  
    setDataError(errObj);
    return valid;
  };
  
  const handleSubmit = () => {
    if (!validateField()) {
      snackBar('Failed to send message.', { variant: 'error' });
      return;
    }
  
    onCompose(data);
  };
  
  return (
    <Box>
      <Box sx={{ maxWidth: '818px', maxHeight: '80vh' }}>
        <Box
          sx={{
            width: { xs: '305px', sm: '540px', md: '600px' },
            minWidth: '305px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '63px',
              borderRadius: '10px',
              backgroundColor: theme.colors?.primary.default,
            }}
          >
            <Stack direction='row' alignItems='center' gap={1}>
              <ReplyIcon sx={{ color: theme.palette.common.white, mr: 1 }} />
              <Typography color={'#ffffff'} fontSize='20px' fontWeight={700}>
                Reply Message
              </Typography>
            </Stack>
          </Box>
          <Box mx={2}>
            <Stack
              direction='column'
              justifyContent='center'
              alignItems='start'
              spacing={2}
              marginTop={3}
            >
              <TextField
                id='outlined-multiline-static'
                multiline
                rows={4}
                label='Max 500 Characters'
                fullWidth
                name='message'
                value={data.message}
                onChange={(e) => handleChange(e)}
                error={!!dataError.message}
                helperText={dataError.message}
              />
            </Stack>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
              marginTop={5}
              mx={2}
            >
              <Button
                variant='contained'
                fullWidth
                sx={{ borderRadius: '9px', height: '50px', backgroundColor: theme.colors?.buttonIcon.default, '&:hover': { backgroundColor: theme.colors?.primary.default } }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant='contained'
                fullWidth
                sx={{ borderRadius: '9px', height: '50px', backgroundColor: theme.colors?.primary.default, '&:hover': { backgroundColor: theme.colors?.buttonIcon.default } }}
              >
                Send
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
  
export default ReplyMessage;
  
import { AxiosResponse, AxiosInstance } from 'axios';
import { IAPIResponse } from '../types/interfaces';

interface IBodyData {
  name: string;
  email: string;
  companyName: string;
  mobile: string;
}
interface IBranchCompanyData extends IBodyData{
  masterCompanyId:string
}

interface IInviteService {
  inviteCompany: (data: IBodyData) => Promise<IAPIResponse>;
  sendMailForEmployee: (id: string) => Promise<IAPIResponse>;
  sendMail: (id: string) => Promise<IAPIResponse>;
  inviteBranchCompanies: (
    data: IBranchCompanyData,
    parentCompanyId: string
  ) => Promise<IAPIResponse<{ companyId: string }>>;
}

const inviteService = (httpClient: AxiosInstance): IInviteService => {
  return {
    inviteBranchCompanies: async (
      data,
      parentCompanyId
    ): Promise<IAPIResponse<{ companyId: string }>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/admin/company/${parentCompanyId}`,
          data
        );

        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to create company.' };
      }
    },
    sendMail: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/admin/company/sent-mail/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to send mail.' };
      }
    },
    inviteCompany: async (data): Promise<IAPIResponse> => {
      console.log('err');

      try {
        const res: AxiosResponse = await httpClient.post(
          '/admin/company',
          data
        );

        return res.data;
      } catch (error) {
        return { status: false, message: 'error' };
      }
    },
    sendMailForEmployee: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/sent-mail/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to send mail.' };
      }
    },
  };
};

export default inviteService;

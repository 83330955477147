import { AxiosInstance, AxiosResponse } from 'axios';
import { IAPIResponse, Obj } from '../types/interfaces';

interface IHomeService {
  createService: (data: Obj) => Promise<IAPIResponse>;
  serviceRequestNames: (type:string) => Promise<
    IAPIResponse<{ _id: string; name: string }[]>
  >;
  getServices: (
    rows: number,
    page: number,
    sortBy: string,
    orderBy: string,
    search: string,
    filter: string
  ) => Promise<IAPIResponse<{ count: number; services: Obj[] }>>;
  setHomeOfficeReporting: (body: any) => Promise<IAPIResponse>;
}

const homeService = (httpClient: AxiosInstance): IHomeService => {
  return {
    createService: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/create/service',
          data
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to create service.' };
      }
    },
    serviceRequestNames: async (type): Promise<
      IAPIResponse<{ _id: string; name: string }[]>
    > => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/service/types/name?type=${type}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get service request types.',
        };
      }
    },
    getServices: async (
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter
    ): Promise<IAPIResponse<{ count: number; services: Obj[] }>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/service/list?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to get services.' };
      }
    },
    setHomeOfficeReporting: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/home-office/request',
          body
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to add services.' };
      }
    },
  };
};

export default homeService;

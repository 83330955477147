import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';


import Success from '../components/common/success/Success';
import { user } from '../service';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { removeAuthStorage } from '../utils';
import SignUpImage from '../components/common/signupImage/SignupImage';

const ChangeEmail = () => {
  

  const theme = useTheme();
  const breakPointSM = useMediaQuery(theme.breakpoints.down('md'));
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar: snackBar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (!params.token) {
      navigate('/');
      snackBar('Invalid Link.', { variant: 'error' });
      return;
    }

    user.verifyEmailChange(params.token).then((res) => {
      if (!res.status) {
        navigate('/');
        snackBar(res.message, { variant: 'error' });
        return;
      }

      setLoading(false);
      removeAuthStorage();
    });
  }, []);

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100vh'
    >
      <Grid
        container
        sx={{
          borderRadius: '13px',
          maxWidth: { xs: '95% !important', md: '70% !important' },
        }}
        boxShadow='0px 8px 44px rgba(0, 0, 0, 0.12)'
        maxWidth={'lg'}
        height={'90%'}
      >
        {!breakPointSM && (
          <Grid item sm={12} md={6} alignItems={'center'}>
            <SignUpImage/>
          </Grid>
        )}
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: 'flex',
          }}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='100%'
            sx={{ padding: { xs: 3, md: 10 } }}
          >
            { loading ? (
              <Box width='100%'>
                <Box width='100%' mb={3}>
                  <CircularProgress color='success' size={70} />
                </Box>
                <Typography
                  fontSize={theme.fontSizes.h2}
                  fontWeight={600}
                  color='#2B4156'
                  textTransform={'uppercase'}
                  mb={2}
                >
                  VERIFYING...
                </Typography>
              </Box>
            ) :
              <Success title='CHANGED EMAIL' content='Your email has been successfully changed.' isLogin={true} />
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangeEmail;
import { Navigate } from 'react-router-dom';

import { useAppState } from '../state/context/AppStateContext';

interface Props {
  component: React.ComponentType;
  path?: string;
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const { authStatus } = useAppState();
  if (authStatus?.token) {
    return <RouteComponent />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
import React, { FC, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Heading from '../components/common/Heading/Heading';
import VerticalTabs from '../components/common/tabs/VerticalTabComponents';
import InboxMessage from '../components/inbox/Messages/InboxMessage';
import SentMessage from '../components/inbox/Messages/SentMessage';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useAppState } from '../state/context/AppStateContext';
interface IInbox {
  show?: boolean;
}
const Inbox: FC<IInbox> = ({ show }) => {
  const [currentPage, setCurrentpage] = useState(0);
  const {newInboxCount}=useAppState();
  const tab = [{ title: `Inbox (${newInboxCount})` }, { title: 'Sent' }];
  const tabPage = [{ page: <InboxMessage /> }, { page: <SentMessage /> }];
  const navigate = useNavigate();
  const { closeSideBar } = useAppState();
  useEffect(() => {
    closeSideBar && closeSideBar();
  }, []);
  return (
    <Box>
      {!show && (
        <Box display='flex' alignItems='center' gap={1}>
          <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
            <ArrowBack color='primary' />
          </IconButton>
          <Heading>Inbox</Heading>
        </Box>
      )}
      <VerticalTabs
        tab={tab}
        tabPage={tabPage}
        currentPage={currentPage}
        setCurrentpage={setCurrentpage}
      />
    </Box>
  );
};

export default Inbox;

import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as svg } from './UserProfile.svg';

interface SortUpIconProps extends SvgIconProps {
  iconColor?: string;
}

const UserProfile: FC<SortUpIconProps> = ({ iconColor, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 37 36'
      component={svg}
      sx={{
        margin: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '10px',
        // '& path': {
        //   fill: iconColor,
        // },
      }}
      {...props}
    />
  );
};

export default UserProfile;

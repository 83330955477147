import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { useAppState } from '../state/context/AppStateContext';
import { ISideDrawer } from '../types/interfaces';
import PaperIcon from './icons/paperIcon/PaperIcon';
import BagIcon from './icons/bagIcon/BagIcon';
import UserIcon from './icons/userIcon/UserIcon';
import SettingIcon from './icons/settingIcon/SettingIcon';
import ResourcesIcon from './icons/resourcesIcon/ResourcesIcon';
import LogoutIcon from './icons/logoutIcon/LogoutIcon';
import OpenIcon from './icons/openIcon/OpenIcon';
import ReportIcon from './icons/reportIcon/ReportIcon';
import { Link, useNavigate } from 'react-router-dom';
import {
  getSideBarState,
  getSideBarStatus,
  removeAuthStorage,
  setSideBarState,
  setSideBarStatus,
} from '../utils';
import CloseIcon from './icons/CloseIcon/CloseIcon';
import MenuIcon from './icons/menuIcon/MenuIcon';
import UserProfile from './icons/UserProfile/UserProfile';
import InboxIcon from './icons/inbox/InboxIcon';
import HomeIcon from './icons/home/HomeIcon';
import { ModalComponent } from './common/modal/ModalComponent';
import ConfirmModal from './common/modal/ConfirmModal';
import RenewalIcon from './icons/RenewalIcon/RenewalIcon';

interface SideBarProps {
  children?: React.ReactNode;
  setDynamicWidth: React.Dispatch<React.SetStateAction<boolean>>;
}

  interface SidebarItem  {
  title: string;
  icon: JSX.Element;
  link?: string;
}

const SideBar: React.FC<SideBarProps> = ({ children, setDynamicWidth }) => {
  const theme = useTheme();
  const { authStatus,setAuthStatus ,activeCompanies} = useAppState();
  const [open, setOpen] = React.useState(false);
  const [selectedTab, setSelected] = React.useState(0);
  const [confirmModal, setConfirmModal] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    const openSet = getSideBarStatus();
    const SideBarState = getSideBarState();
    if (SideBarState) {
      setSelected(SideBarState.state);
    }
    setOpen(openSet);
    setDynamicWidth(openSet);
  }, []);
  const Logout = ()=>{
    const logout: boolean = removeAuthStorage();
    if (logout) {
      setAuthStatus&&setAuthStatus(undefined);

      navigate('/login');
    }
  };
  const handleCloseConfirm = ()=>{
    setConfirmModal(false);
  };
  const handleOpen = ()=>{
    setConfirmModal(true);
  };

  const sideBarConstants:Record<string, SidebarItem>={
    Compliance: { title: 'Compliance', icon: <PaperIcon fontSize='large' /> },
    Company: { title: 'Company', icon: <BagIcon fontSize='large' />, link: '/company' },
    Employees: {
      title: 'Employees',
      icon: (
        <UserIcon
          fontSize='large'
          sx={{ color: theme.colors?.secondary.default, borderRadius: '100%' }}
        />
      ),
      link: '/employee',
    },
    Renewals:  { title: 'Renewals', icon: <RenewalIcon fontSize='large'/>,link:'/renewal'  },
    Services:{
      title: 'Services',
      icon: <MenuIcon fontSize='large' />,
      link: '/services',
    },
    
    Messages: { title: 'Messages', icon: <InboxIcon fontSize='large' />, link: '/messages' },
    Reports:{ title: 'Reports', icon: <ReportIcon fontSize='large' />,link:'/reports' },
    Settings: {
      title: 'Settings',
      icon: <SettingIcon fontSize='large' />,
      link: '/settings',
    },
    Resources:  { title: 'Resources', icon: <ResourcesIcon fontSize='large' />,link:'/resources' },
    Dashboard:  {
      title: 'Dashboard',
      icon: <HomeIcon fontSize='large' />,
      link: '/dashboard',
    },
    Profile:{
      title: 'Profile',
      icon: (
        <UserProfile
          fontSize='large'
          sx={{ color: theme.colors?.secondary.default, borderRadius: '100%' }}
        />
      ),
      link: '/employee-Details',
    },
    Matters:     {
      title: 'Matters',
      icon: <HomeIcon fontSize='large' />,
      link: '/dashboard',
    },
    DashboardMaster:  {
      title: 'Dashboard',
      icon: <HomeIcon fontSize='large' />,
      link: '/multi-dashboard',
    },

  };

  const sideApp : Array<string> = authStatus?.isMonthlyCheckEnabled?  authStatus?.roleSettings?.viewReports ?
    ['Compliance','Company','Employees','Services','Renewals','Messages','Reports','Settings','Resources'] :
    ['Compliance','Company','Employees','Services','Renewals','Messages','Settings','Resources']:['DashboardMaster','Services','Messages','Reports','Settings','Resources'];
  
  if( authStatus?.isMonthlyCheckEnabled && activeCompanies && activeCompanies?.length>1){
    sideApp.unshift('DashboardMaster');
  }
  const empApp: Array<string> =['Dashboard','Profile','Messages','Settings','Resources',];

  const clientApp: Array<string> =   ['Matters','Messages','Settings',];

  const boxWidth = open ? '250px' : '40px';
  return (
    <Box
      sx={{
        height: '100%',
        width: open ? '250px' : '85px',
        position: 'static',

        p: 3,
        mt: 2,
        mb: 2,
        borderRadius: '10px',
        background: theme.colors?.primary.default,
        transition: '.5s',
        overflowY: 'auto',
        overflowX: 'hidden',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',

          // justifyContent: 'space-between',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Grid container direction='column' rowGap={4}>
          <Grid item>
            {!open && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <IconButton
                  // sx={{ pl: '3px' }}
                  onClick={() => {
                    setSideBarStatus(!open);
                    setOpen(!open);
                    setDynamicWidth(!open);
                  }}
                >
                  <OpenIcon />
                </IconButton>
              </Box>
            )}

            {open && (
              <Box
                sx={{
                  width: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  onClick={() => {
                    setSideBarStatus(!open);
                    setOpen(!open);
                    setDynamicWidth(!open);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Grid>

          {(authStatus?.role === 'business_employee'
            ? empApp
            : authStatus?.role === 'client'
              ? clientApp
              : sideApp
          )?.map((i:string, index) => {
            const item= sideBarConstants[i];
            return (
              <Tooltip title={item.title} key={index}>
                <Grid item>
                  <Link
                    to={item?.link ?? '/'}
                    style={{ textDecoration: 'none' }}
                  >
                    <Box
                      sx={
                        selectedTab === index
                          ? {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: open
                              ? 'flex-start'
                              : 'flex-start',
                            // borderBottom: `2px solid ${theme.colors?.warning.default}`,
                            mr: 3,
                          }
                          : {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: open
                              ? 'flex-start'
                              : 'flex-start',
                          }
                      }
                      onClick={() => {
                        // setOpen(false);
                        // setDynamicWidth(false);
                        setSelected(index);
                        setSideBarState(index);
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom:
                            selectedTab === index
                              ? `3px solid ${theme.colors?.warning.default}`
                              : '',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: open ? 'flex-start' : 'flex-start',
                        }}
                      >
                        {item.icon}
                      </Box>

                      {open && (
                        <Typography
                          color='secondary'
                          fontSize={'medium'}
                          paddingLeft='20px'
                        >
                          {' '}
                          {item.title}
                        </Typography>
                      )}
                    </Box>
                  </Link>
                </Grid>
              </Tooltip>
            );
          })}
        </Grid>

        <Grid container>
          <Tooltip title='Logout'>
            <Grid
              item
              sx={{
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: 2,
                }}
                onClick={handleOpen}
              >
                <LogoutIcon fontSize='large' />
                {open && (
                  <Typography
                    color='secondary'
                    fontSize={'medium'}
                    paddingLeft='20px'
                  >
                    {' '}
                    Log out
                  </Typography>
                )}
              </Box>
            </Grid>
          </Tooltip>
        </Grid>
      </Box>
      <Box sx={{ mt: 4 }}>{children}</Box>
      <ModalComponent isOpen={confirmModal} isClose={handleCloseConfirm}>
        <ConfirmModal
          onSubmit={()=>{Logout();}}
          isClose={handleCloseConfirm}
          message='Are you sure you want to logout?'
          ButtonText='Logout'
        />
      </ModalComponent>
    </Box>
  );
};

export default SideBar;

import { AxiosResponse, AxiosInstance } from 'axios';
import { IAPIResponse, Obj } from '../types/interfaces';



interface IResourceService {
  uploadResourceDocument: (body: Obj) => Promise<IAPIResponse<Obj[]>>;
  getResouceDocs: (filterQuery:string) => Promise<IAPIResponse<Obj[]>>;
  deleteResouceDocs: (docId:string) => Promise<IAPIResponse>;
  updateResouceDocs: (body: Obj) => Promise<IAPIResponse>;
  riseNewTicket: (body: Obj) => Promise<IAPIResponse<Obj[]>>;
  getAllTicketTypes: () => Promise<IAPIResponse<{name:string,_id:string}[]>>;
     getRaisedTickets: (
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    filterQuery?: string
  ) => Promise<IAPIResponse<{ count: number; documentTypes: Obj[] }>>;
    deleteRaisedTicket: (id: string) => Promise<IAPIResponse>;
      getAllDocsCategories: () => Promise<IAPIResponse<{value:string,_id:string}[]>>;

}

const resourceService = (httpClient: AxiosInstance): IResourceService => {
  return {
    uploadResourceDocument: async (body:Obj): Promise<IAPIResponse<Obj[]>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/resources/resources-document',
          body
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
    getResouceDocs: async  (filterQuery) : Promise<IAPIResponse<Obj[]>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/resources/resources-documents?filterQuery=${filterQuery}`,
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    deleteResouceDocs: async  (id) : Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/resources/resources-document/${id}`,
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    updateResouceDocs: async  (documentData) : Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/resources/resources-document',
          documentData
        );        
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
            
      }
    },
    riseNewTicket: async (body:Obj): Promise<IAPIResponse<Obj[]>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/create/ticket',
          body
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
        
    getAllTicketTypes: async (): Promise<IAPIResponse<{name:string,_id:string}[]>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/ticket-type/all/list'
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
    getRaisedTickets: async (
      rows,
      page,
      sortBy,
      orderBy,
      filterQuery
    ): Promise<IAPIResponse<{ count: number; documentTypes: Obj[] }>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/raised-tickets/list?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&type=${filterQuery}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
    deleteRaisedTicket: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/resources/raised-ticket/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
    getAllDocsCategories: async (): Promise<IAPIResponse<{value:string,_id:string}[]>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/document-category/all/list'
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },

  };
};

export default resourceService;

import { AxiosInstance, AxiosResponse } from 'axios';
import { IAPIResponse, Obj } from '../types/interfaces';

interface IAuthService {
  login: (email: string, password: string) => Promise<IAPIResponse>;
  mfa: (data: Obj) => Promise<IAPIResponse>;
  tokenVerify: (token: string) => Promise<IAPIResponse>;
  userAccountSetUp: (
    token: string,
    name: string,
    email: string,
    countryCode: string,
    mobile: string,
    password: string,
    companyId: string
  ) => Promise<IAPIResponse>;
  employeeAccountSetUp: (
    token: string,
    name: string,
    email: string,
    countryCode: string,
    mobile: string,
    password: string,
    companyId: string,
    userId: string
  ) => Promise<IAPIResponse>;
  clientAccountSetUp: (
    token: string,
    name: string,
    email: string,
    countryCode: string,
    mobile: string,
    password: string,
    userId: string
  ) => Promise<IAPIResponse>;

  forgetPassword: (email: string) => Promise<IAPIResponse>;
  resetPassword: (token: string, password: string) => Promise<IAPIResponse>;
  resetPasswordTokenVerify: (token: string) => Promise<IAPIResponse>;
  resendMfaOtp: (id: string) => Promise<IAPIResponse>;
}

const authService = (httpClient: AxiosInstance): IAuthService => {
  return {
    login: async (email: string, password: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/auth/sign-in',
          {
            username: email,
            password,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid email or password.' };
      }
    },
    mfa: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put('/app/user/otp/verify', data);
        return res.data;
      } catch (error) {
        return { status: false, message: 'Login failed.' };
      }
    },
    resendMfaOtp: async (id: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(`/app/user/re-send/otp/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: 'Login failed.' };
      }
    },
    tokenVerify: async (token): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/token-verification/${token}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    userAccountSetUp: async (
      token,
      name,
      email,
      countryCode,
      mobile,
      companyId,
      password
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company-signup/${token}`,
          {
            name,
            email,
            countryCode,
            mobile,
            password,
            companyId,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    clientAccountSetUp: async (
      token,
      name,
      email,
      countryCode,
      mobile,
      password,
      userId
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/client-user/signup/${token}`,
          {
            name,
            email,
            countryCode,
            mobile,
            password,
            userId,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    employeeAccountSetUp: async (
      token,
      name,
      email,
      countryCode,
      mobile,
      companyId,
      password,
      userId
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company-user/signup/${token}`,
          {
            name,
            email,
            countryCode,
            mobile,
            password,
            companyId,
            userId,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    forgetPassword: async (email): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.patch(
          '/app/user/forget-password',
          { email }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to send mail.', data: {} };
      }
    },
    resetPassword: async (token, password): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.patch(
          '/app/user/reset-password/verify',
          { token, password }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to send mail.', data: {} };
      }
    },
    resetPasswordTokenVerify: async (token): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/forget/password/${token}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to verify token.', data: {} };
      }
    },
  };
};

export default authService;

import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { Obj } from '../../../types/interfaces';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import { inbox } from '../../../service';
import moment from 'moment';
import { MoreVert } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ModalComponent } from '../../common/modal/ModalComponent';
import ConfirmModal from '../../common/modal/ConfirmModal';

const SentMessage = () => {
  const [data, setData] = useState<{ count: number; inboxMessages: Obj[] }>({
    count: 0,
    inboxMessages: [],
  });
  const [page, setPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar: snackBar } = useSnackbar();
  const navigate = useNavigate();
  const [confirmModalOpen,setConfirmModalOpen]=useState(false);  

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSentMessages = (rows: number, page: number) => {
    inbox.sentMessages(rows, page, search).then((res) => {
      if (!res.status) {
        setData({ count: 0, inboxMessages: [] });
        snackBar(res.message, { variant: 'error' });
        return;
      }

      res.data && setData(res.data);
    });
  };
  const handleCloseConfirmModal = ()=>{
    setConfirmModalOpen(false);
  };
  const handleDelete = () => {
    inbox.delete(selected).then((res) => {
      if (!res.status) {
        snackBar(res.message, { variant: 'error' });
        return;
      }
      snackBar(res.message, { variant: 'success' });
      handleClose();
      handleCloseConfirmModal();
      handleSentMessages(recordsPerPage, page);
    }).catch((res)=>{
      snackBar('Something wrong', { variant: 'error' });
    });
  };
  useEffect(() => {
    handleSentMessages(recordsPerPage, page);
  }, [recordsPerPage, page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRecordsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Box
        component='form'
        display='flex'
        // flexDirection={!mobileMode ? 'row' : 'column'}
        justifyContent={'space-between'}
        gap={1}
        sx={{ mt: { xl: 0, xs: 2 } }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSentMessages(recordsPerPage, page);
        }}
      >
        <InputBase
          sx={{
            pl: 3,
            flex: 1,
            backgroundColor: 'rgba(43, 65, 86, 0.08)',
            borderRadius: '9px',
            height: '50px',
          }}
          placeholder='Search'
          inputProps={{
            'aria-label': 'Search for users',
          }}
          value={search}
          startAdornment={<SearchIcon sx={{ color: '#B3B3B3' }} />}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Box
        marginTop={3}
        alignItems={'center'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        {data.inboxMessages.map((item, index) => (
          <Paper
            sx={{
              width: '100%',
              p: 2,
              height: '70px',
            }}
            key={index}
            // elevation={item.isRead ? 0 : 2}
            elevation={0}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Typography minWidth={'30%'} color={'primary'} fontWeight={600} align='left'>
                {item.companyName ? item.companyName : item.userName}
              </Typography>
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  ':hover': { cursor: 'pointer' },
                }}
                onClick={() => {
                  navigate(`/message/${item._id}`);
                }}
              >
                {item.subject}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>
                  {item.date && moment(item.date).format('DD/MM/YYYY')}
                </Typography>
                <IconButton
                  onClick={(e) => {
                    setSelected(item._id);
                    handleClick(e);
                  }}
                >
                  <MoreVert />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      navigate(`/message/${selected}`);
                    }}
                  >
                    View Message
                  </MenuItem>
                  <MenuItem onClick={() => {
                    setSelected(item._id);
                    setConfirmModalOpen(true);}}>
                    Delete
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Paper>
        ))}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              navigate(`/message/${selected}`);
            }}
          >
                    View Message
          </MenuItem>
          <MenuItem onClick={() => {
            setConfirmModalOpen(true);}}>
                    Delete
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        {data.count > 0 && (
          <TablePagination
            component='div'
            count={data.count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={recordsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
      <ModalComponent isOpen={confirmModalOpen} isClose={handleCloseConfirmModal}>
        <ConfirmModal
          onSubmit={handleDelete}
          isClose={handleCloseConfirmModal}
          message={'Are you sure you want to delete this message?'}
        />
      </ModalComponent>
    </Box>
  );
};

export default SentMessage;

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Badge,
  Divider,
  ListItemIcon,
  Paper,
  Stack,
  SvgIconProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AnyFunction } from '../types/interfaces';
import Logo from './Logo';
import MessageIcon from './icons/Message/MessageIcon';
import NotificationBell from './icons/NotificationBell/NotificationBell';
import { useAppState } from '../state/context/AppStateContext';
import DropDownIcon from './icons/DropdownIcon/DropDownIcon';
import MenuIcon from '@mui/icons-material/Menu';
import LogoForMobile from './icons/LogoForMobile/LogoForMobile';
import { inbox, user } from '../service';
import audio from '../audio/notification.mp3';
import moment from 'moment';
interface AMAppBarProps {
  children?: React.ReactNode;
  pages?: Array<{ name: string; path: string }>;
  settings?: Array<{
    name: string;
    icon: React.ComponentType<SvgIconProps>;
    onClick?: VoidFunction;
    path?: string | AnyFunction;
  }>;
  SetOpenSide?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AMAppBar: React.FC<AMAppBarProps> = ({ settings = [], SetOpenSide }) => {
  const regex = /(<([^>]+)>)/gi;

  const theme = useTheme();
  const {
    authStatus,
    newInboxCount,
    activeCompanies,
    setNewInboxCount,
    setNewNotification,
  } = useAppState();
  const navigate = useNavigate();
  const [newNotificationCount, setNewNotificationCount] =
    React.useState<number>(0);
  const [notificationData, setNotificationData] = React.useState<any>([]);

  const [messageData, setMessageData] = React.useState<any>([]);
  const mobile = useMediaQuery('(max-width:500px)');
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const openNotification = Boolean(anchorElNotification);
  const handleClickNotification = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNotification(event.currentTarget);
    setNewNotificationCount(0);
  };
  const  handleClickHelpCenter=()=> {
    window.open('https://help.citylegalsolicitors.co.uk/');
  };
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (path?: string | AnyFunction) => {
    setAnchorElUser(null);

    if (path && typeof path === 'function') {
      path();
      window.location.href = '/login';
      return;
    }

    if (path && typeof path === 'string') {
      navigate(path);
      return;
    }
  };
  function play() {
    new Audio(audio).play();
  }
  // React.useEffect(() => {
  //   getMessage();
  // }, []);

  const getNotification = () => {
    user.getNotificationStatus().then((response) => {
      if (response.status && response.data) {
        if (response.data.count === 0) return;

        setNewNotificationCount((prev) => prev + response.data.count);
        setNewNotification && setNewNotification((prev: boolean) => !prev);
        setNotificationData((prev: any) => [
          ...response.data.notifications,
          ...prev,
        ]);

        play();
      }
    });
  };

  // const getMessage = () => {
  //   user.getUnreadMessage().then((response) => {
  //     if (response.status && response.data) {
  //       setNewInboxCount && setNewInboxCount(response.data.count);
  //       setMessageData(response.data?.messages);
  //     }
  //   });
  // };
  React.useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      getNotification();
      // getMessage();
    }, 30000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  const handleUpdateRead = (id: string, replyId: string) => {
    if (replyId === authStatus?.companyId || replyId === authStatus?.userId) {
      inbox.updateRead(id).then((res) => {
        if (!res.status) {
          return;
        }
        setNewInboxCount && setNewInboxCount((prev) => prev - 1);
        navigate(`/message/${id}`);
        const data = [...messageData];
        const newData = data.filter((item) => item._id !== id);

        setMessageData(newData);
        handleClose();
        return;
      });
    }
  };
  return (
    <AppBar
      position='static'
      sx={{
        borderRadius: '10px',
        background: theme.colors?.secondary.default,
        width: '100%',
        minHeight: '75px',
      }}
    >
      <Container sx={{ minWidth: '100%' }}>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          {mobile ? (
            <Box
              sx={{
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <LogoForMobile
                fontSize='large'
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  mr: 0,
                  width: 'auto',
                }}
              />
              <IconButton
                onClick={() => SetOpenSide && SetOpenSide((prev) => !prev)}
              >
                <MenuIcon color='primary' />
              </IconButton>
            </Box>
          ) : (
            <Box display={'flex'}>
              <Logo
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  mr: 0,
                  width: '160px',
                }}
              />
              <Divider orientation='vertical' variant='middle' />
              {authStatus?.companyLogo && (
                <Box maxWidth={'160px'} maxHeight={'40px'} marginLeft={5}>
                  <img
                    style={{ objectFit: 'contain' }}
                    src={authStatus?.companyLogo}
                    width={'100%'}
                    height={'40px'}
                  />
                </Box>
              )}
            </Box>
          )}

          <Box
            sx={{
              flexGrow: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='flex-end'
              spacing={1}
            >
          
              <Tooltip title='Help Center'>
                <IconButton
                  sx={{width:'15px',':hover':{backgroundColor:'#ffff'}}}
                  onClick={handleClickHelpCenter}
                >
                
                  <HelpOutlineIcon fontSize='large' sx={{p:.6}} color='primary' />
              
                </IconButton>
              </Tooltip>
              <Tooltip title='Notification'>
                <IconButton
                  aria-controls={openNotification ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openNotification ? 'true' : undefined}
                  onClick={handleClickNotification}
                  sx={{':hover':{backgroundColor:'#ffff'}}}
                >
                  <Badge badgeContent={newNotificationCount} color='error'>
                    <NotificationBell fontSize='large' sx={{p:.1}} />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                id='basic-menu'
                anchorEl={anchorElNotification}
                open={openNotification}
                onClose={handleCloseNotification}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{
                  '& .MuiMenu-list': {
                    padding: 0,
                  },
                }}
              >
                <Paper
                  sx={{
                    width: '450px',
                    height: '350px',
                    padding: 3,
                    maxWidth: '100%',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography fontWeight={600} color={'primary'}>
                        Notification
                      </Typography>
                      <Link
                        to={'/notification'}
                        onClick={() => handleCloseNotification()}
                        style={{ color: '#002E51' }}
                      >
                        View All
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          display: 'inline',
                          width: 5,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: theme.colors?.secondary.default,
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.colors?.primary.default,
                          borderRadius: 2,
                        },
                      }}
                    >
                      {notificationData.length !== 0 ? (
                        notificationData.map((item: any, index: number) => {
                          return (
                            <Link
                              to={'/notification'}
                              onClick={() => handleCloseNotification()}
                              style={{
                                color: '#002E51',
                                textDecoration: 'none',
                              }}
                              key={index}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'normal',
                                  justifyContent: 'space-between',
                                  marginTop: 3,
                                }}
                                key={index}
                              >
                                <Box>
                                  <Avatar sx={{ mt: 0 }}>
                                    {authStatus?.name.charAt(0)}
                                  </Avatar>
                                </Box>
                                <Box minWidth={'80%'} ml={2}>
                                  <Typography
                                    color={'primary'}
                                    align='left'
                                    fontWeight={600}
                                  >
                                    {' '}
                                    {item.title}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {item.description}
                                  </Typography>
                                  <Typography>
                                    {moment(item.createdAt).fromNow()}
                                  </Typography>
                                </Box>
                                <Box></Box>
                              </Box>
                            </Link>
                          );
                        })
                      ) : (
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography>No new notification</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Menu>
              {!mobile && (
                <>
                  <Box>
                    <Avatar
                      alt={authStatus?.name.charAt(0)}
                      src={authStatus?.profileImage}
                      // sx={{'& img': {
                      //   objectFit: 'cover', // or "contain" or "fill" depending on your preference
                      //   objectPosition: 'center', // adjust as needed
                      // },}}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: { xs: 'none', md: 'flex', width: 'auto' },
                      flexDirection: 'column',
                    }}
                  >
                    <Typography color='primary' align='center'>
                      {authStatus?.name}
                    </Typography>
                    <Typography fontSize={'small'} color='primary'>
                      Joined: {authStatus?.joined}
                    </Typography>
                  </Box>{' '}
                </>
              )}
              <Box>
                <Tooltip title='Open settings'>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <DropDownIcon fontSize='large' />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu()}
              >
                {activeCompanies && activeCompanies.length > 1 && (
                  <MenuItem>
                    <Typography
                      textAlign='center'
                      variant={'subtitle2'}
                      color={theme.colors?.primary.default}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                      }}
                    >
                      {authStatus?.companyName ?? ''}
                    </Typography>
                  </MenuItem>
                )}
                {(settings || []).map((setting) => (
                  <MenuItem
                    key={setting.name}
                    onClick={() => {
                      setting.onClick && setting.onClick();
                    }}
                  >
                    <ListItemIcon>
                      {setting.icon ? <setting.icon fontSize='small' /> : null}
                    </ListItemIcon>
                    <Typography textAlign='center'>{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AMAppBar;

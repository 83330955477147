import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import SelectCurvedOutline from '../common/select/SelectCurvedOutline';
import { company } from '../../service';
import { useAppState } from '../../state/context/AppStateContext';
import { useSnackbar } from 'notistack';
import { IAuth } from '../../types/interfaces';

interface IChangeCompanyModalProps {
  handleClose: VoidFunction;
}

interface IActiveCompanies {
  _id: string;
  name: string;
  image: string;
}

const ChangeCompanyModal = ({ handleClose }: IChangeCompanyModalProps) => {
  const theme = useTheme();
  const { authStatus, setAuthStatus, activeCompanies } = useAppState();
  // const [userId, setUserId] = useState(authStatus?.userId ?? '');
  const [companyId, setCompanyId] = useState(authStatus?.companyId ?? '');
  const { enqueueSnackbar: snackBar } = useSnackbar();
  // const [activeCompanies, setActiveCompanies] =
  //   useState<IActiveCompanies[]>([]);
  const [data, setData] = useState({
    _id: authStatus?.companyId ?? '',
    name: authStatus?.companyName ?? '',
    image: authStatus?.companyLogo ?? '',
  });
  const changeHandler = (e: SelectChangeEvent<unknown>) => {
    activeCompanies?.forEach((item) => {
      if (item._id === e.target.value) {
        setData(item);
      }
    });
    setCompanyId(e.target.value as unknown as string);
  };

  // const getCompanyList = async () => {
  //   try {
  //     if (!authStatus?.userId) {
  //       return;
  //     }
  //     const response = await company.getAccessCompanyList(authStatus?.userId);
  //     if (response.status && response.data) {
  //       setActiveCompanies([...activeCompanies, ...response.data]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching company list:', error);
  //   }
  // };

  const handleChangeCompany = async () => {
    try {
      const response = await company.changeCompany(companyId);
      if (!response.status) {
        snackBar(response.message, { variant: 'error' });
        return;
      }
      const newData: IAuth = {
        ...authStatus,
        ...response.data,
      };
      setAuthStatus && setAuthStatus({ ...newData });
      handleClose();


      let link = '/';
      if (
        (    response.data.accessCompanies &&
        response.data.accessCompanies.length > 1) || !response.data.isMonthlyCheckEnabled
      ) {
        link = '/multi-dashboard';
      }
      window.location.href = link;
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getCompanyList();
  // }, []);
  return (
    <Box>
      <Box sx={{ maxWidth: '818px', maxHeight: '80vh' }}>
        <Box
          sx={{
            width: { xs: '305px', sm: '540px', md: '600px' },
            minWidth: '305px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #002E51',
              height: '63px',
              borderRadius: '10px',
              backgroundColor: '#2B4156',
            }}
          >
            <Stack direction='row' alignItems='center' gap={1}>
              <ChangeCircleOutlinedIcon
                sx={{ color: theme.palette.common.white, mr: 1 }}
              />
              <Typography color={'#ffffff'} fontSize='20px' fontWeight={700}>
                {' '}
                Change Company
              </Typography>
            </Stack>
          </Box>
          <Box minHeight={200} px={2} width={'100%'}>
            <Stack>
              <Box
                minHeight={100}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {data?.image && (
                  <Box maxWidth={'160px'} maxHeight={'40px'}>
                    <img
                      style={{ objectFit: 'contain' }}
                      src={data?.image}
                      width={'100%'}
                      height={'40px'}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <SelectCurvedOutline
                  value={companyId}
                  defaultValue={data._id}
                  label='Select Company'
                  onChange={changeHandler}
                  // disabled={!!params.id}
                >
                  {activeCompanies?.map((activeCompany) => (
                    <MenuItem key={activeCompany._id} value={activeCompany._id}>
                      {activeCompany.name}
                    </MenuItem>
                  ))}
                </SelectCurvedOutline>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 4,
              pb: 2,
            }}
          >
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
              width='100%'
            >
              <Button
                variant='contained'
                size='large'
                sx={{
                  backgroundColor: theme.colors?.buttonIcon.default,
                  width: '260px',
                  height: '60px',
                  borderRadius: '17px',
                }}
                onClick={handleClose}
              >
                cancel
              </Button>
              <Button
                onClick={handleChangeCompany}
                variant='contained'
                size='large'
                sx={{
                  backgroundColor: theme.colors?.primary.default,
                  width: '260px',
                  height: '60px',
                  borderRadius: '17px',
                }}
              >
                save
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangeCompanyModal;

import { createContext, useContext, Dispatch, SetStateAction } from 'react';

export interface ITableDataContext {

   companyPage?:number; 
   setCompanyPage?: React.Dispatch<React.SetStateAction<number>>;
    companyRecordsPerPage?:number;
     setCompanyRecordsPerPage?:React.Dispatch<React.SetStateAction<number>>;
    companySortBy?:string;
     setCompanySortBy?:React.Dispatch<React.SetStateAction<string>>;
    companyAsc?:boolean;
     setCompanyAsc?:React.Dispatch<React.SetStateAction<boolean>>;
    companySearch?:string;
     setCompanySearch?:React.Dispatch<React.SetStateAction<string>>;
     companyFilterValue?:number;
      setCompanyFilterValue?:React.Dispatch<React.SetStateAction<number>>;
       companyTypeData:string; 
       setCompanyTypeData?:React.Dispatch<React.SetStateAction<string>>;


        employeePage?:number;
         setEmployeePage?:React.Dispatch<React.SetStateAction<number>>;

    employeeRecordsPerPage?:number;
     setEmployeeRecordsPerPage?:React.Dispatch<React.SetStateAction<number>>;

    employeeSortBy?:string;
     setEmployeeSortBy?:React.Dispatch<React.SetStateAction<string>>;

    employeeAsc?:boolean;
     setEmployeeAsc?:React.Dispatch<React.SetStateAction<boolean>>;

    employeeSearch?:string;
     setEmployeeSearch?:React.Dispatch<React.SetStateAction<string>>;

    employeeFilterValue?:number;
     setEmployeeFilterValue?:React.Dispatch<React.SetStateAction<number>>;
}

const TableDataContext = createContext<ITableDataContext | undefined>(
  undefined
);

export const useTableData = () => {
  const context = useContext(TableDataContext);
  if (!context) {
    throw new Error(
      'useTableData must be used within an TableDataProvider'
    );
  }
  return context;
};

export default TableDataContext;

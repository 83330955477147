import { IAPIResponse, Obj } from './../types/interfaces/index';
import { AxiosInstance, AxiosResponse } from 'axios';

interface IEmployeeService {
  setEmployeeProfile: (id: string, body: any) => Promise<IAPIResponse>;
  getEmployeeData: (key: string, id: string) => Promise<IAPIResponse<any>>;
  deleteCompanyDocument: (key: string) => Promise<IAPIResponse>;
  inviteEmployee: (body: any) => Promise<IAPIResponse>;
  getEmployeeList: (
    rows: number,
    page: number,
    sortBy: string,
    orderBy: string,
    search: string,
    filter: string,
    deleted: string
  ) => Promise<IAPIResponse<Obj>>;
  getEmployeeDataForEmployee: (key: string) => Promise<IAPIResponse>;
  setEmployeeDataForEmployee: (body: any) => Promise<IAPIResponse>;
  getEmployeeDocumentData: (
    id: string | undefined,
    isRestricted: boolean
  ) => Promise<IAPIResponse>;
  getAddressList: (id: string | undefined) => Promise<IAPIResponse>;
  getAddressListEmployee: () => Promise<IAPIResponse>;
  getEmployeeProfileDocument: (isRestricted?: boolean) => Promise<IAPIResponse>;
  getEmployeeProfilePolicyDocument: () => Promise<IAPIResponse>;
  addEmployeeDocumentData: (formData: FormData) => Promise<IAPIResponse>;
  setEmployeeDocumentData: (formData: FormData) => Promise<IAPIResponse>;
  getRestrictedDocument: (id: string) => Promise<IAPIResponse>;
  addRestrictedDocumentData: (formData: FormData) => Promise<IAPIResponse>;
  setRestrictedDocumentData: (formData: FormData) => Promise<IAPIResponse>;
  deleteRestrictedDocument: (id: string) => Promise<IAPIResponse>;
  getEmployeeStatus: (companyId: string) => Promise<IAPIResponse>;
  getAllLeaveTypes: () => Promise<IAPIResponse<{name:string,_id:string,leaveType:string}[]>>;
  readLeaveType: (
    id:string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
   
    // filterValue?: number,
    filterValue?: string,
    // filter?: string,
  ) => Promise<IAPIResponse<{ count: number;leaveCountData: Obj[]; leaveRequests: Obj[] }>>;
  createNewLeaveType: (data: Obj,id:string) => Promise<IAPIResponse>;
  updateNewLeaveType: (data: Obj) => Promise<IAPIResponse>;
  deleteLeaveType: (id: string) => Promise<IAPIResponse>;
  setAccurateDataEmployee: (
    key: string,
    userId: string
  ) => Promise<IAPIResponse>;
  getIsMonthlyCheck: (id: string) => Promise<IAPIResponse>;
  employeeProfileHistory: (
    id: string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    search?: string,
    filter?: number,
    completionTracking?: string
  ) => Promise<IAPIResponse<any>>;
  setEmployeeMonthlyCheckStatus: (
    body: any,
    id: string
  ) => Promise<IAPIResponse>;
  getEmployeeListName: () => Promise<IAPIResponse>;
  getEmployeeCompliance: () => Promise<
    IAPIResponse<{
      documents: boolean;
      employeeProfile: boolean;
      employment: boolean;
    }>
  >;
  createService: (data: Obj) => Promise<IAPIResponse>;
  getServices: (
    rows: number,
    page: number,
    sortBy: string,
    orderBy: string,
    search: string,
    filter: string | number
  ) => Promise<IAPIResponse<{ count: number; services: Obj[] }>>;
  getEmployeeDeletedStatus: (id: string) => Promise<IAPIResponse>;
  getReminders: (
    id: string | undefined,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string
  ) => Promise<IAPIResponse<any>>;
  updateReminderDetails: (id: string, data: any) => Promise<IAPIResponse<any>>;
  getCustomReminders: (
    id: string | undefined,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string
  ) => Promise<IAPIResponse<any>>;
  updateCustomReminderDetails: (
    id: string,
    data: any
  ) => Promise<IAPIResponse<any>>;
  deleteNotes: (id: string) => Promise<IAPIResponse<any>>;
  createNote: (id: string, formData: FormData) => Promise<IAPIResponse>;
  getNotes: (
    id: string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    search?: string,
    filterQuery?: string,
    deleted?: string,
    assignedToMe?: string
  ) => Promise<IAPIResponse<Obj>>;
}

const employeeService = (httpClient: AxiosInstance): IEmployeeService => {
  return {
    setEmployeeProfile: async (id, body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company-employee/edit/${id}`,
          body
        );
        return res?.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    getEmployeeData: async (key, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employee/details/?key=${key}&userId=${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    inviteEmployee: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/company-user',
          body
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    getEmployeeList: async (
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter,
      deleted
    ): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employees/list?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}&deleted=${deleted}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getEmployeeDataForEmployee: async (key): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company-employee/details/?key=${key}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    setEmployeeDataForEmployee: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/company-user/profile',
          body
        );
        return res?.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getEmployeeDocumentData: async (
      id,
      isRestricted
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/employee/documents/${id}/${isRestricted}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    getAddressList: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/addresses-list/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getAddressListEmployee: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/addresses-list'
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    getEmployeeProfileDocument: async (isRestricted): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employee/documents/${isRestricted}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getEmployeeProfilePolicyDocument: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/employee/policy'
  
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    addEmployeeDocumentData: async (
      formData: FormData
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/employee/other-document',
          formData
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    setEmployeeDocumentData: async (
      formData: FormData
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/employee/document-upload',
          formData
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    getEmployeeStatus: async (companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employee/profile/status?userId=${companyId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getRestrictedDocument: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/restricted/document/list/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    deleteRestrictedDocument: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/restricted/document/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    addRestrictedDocumentData: async (
      formData: FormData
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/restricted/document',
          formData
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    setRestrictedDocumentData: async (
      formData: FormData
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/restricted/document',
          formData
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    setAccurateDataEmployee: async (key, userId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/employee/profile-data/status?key=${key}&userId=${userId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getIsMonthlyCheck: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employee/monthly-check/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    employeeProfileHistory: async (
      id,
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter,
      completionTracking
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employee/profile/history/${id}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}&completionTracking=${completionTracking}`
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to get company profile history.',
        };
      }
    },

    setEmployeeMonthlyCheckStatus: async (body, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.patch(
          `/app/user/employee/monthly/check/${id}`,
          body
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to update monthly check status.',
        };
      }
    },
    getEmployeeListName: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/business/employees'
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to update monthly check status.',
        };
      }
    },
    getEmployeeCompliance: async (): Promise<
      IAPIResponse<{
        documents: boolean;
        employeeProfile: boolean;
        employment: boolean;
      }>
    > => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/employee/dashboard'
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get employee compliance.' };
      }
    },
    createService: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/employee/service',
          data
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to create service.' };
      }
    },
    getServices: async (
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter
    ): Promise<IAPIResponse<{ count: number; services: Obj[] }>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employee/service/list?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed to get services.' };
      }
    },
    deleteCompanyDocument: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `app/user/employee/document/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    getEmployeeDeletedStatus: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `app/user/delete/status/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getReminders: async (
      id,
      rows,
      page,
      sortBy,
      orderBy
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/system/reminder/${id}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    updateReminderDetails: async (id, data): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/system/reminder/${id}`,
          data
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to update reminder details.' };
      }
    },
    getCustomReminders: async (
      id,
      rows,
      page,
      sortBy,
      orderBy
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/get/note/${id}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    updateCustomReminderDetails: async (
      id,
      data
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/create/note/${id}`,
          data
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to update reminder details.' };
      }
    },
    deleteNotes: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/delete/note/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    createNote: async (id: string, data: FormData): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/create/note/${id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    getNotes: async (
      id,
      rows,
      page,
      sortBy,
      orderBy
    ): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/get/note/${id}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    getAllLeaveTypes: async (): Promise<IAPIResponse<{name:string,_id:string,leaveType:string}[]>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/company/employee/leave-types'
        );
        return {...res.data, data: res.data?.data?.leaveTypes};
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
    createNewLeaveType: async (data,id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/employee/leave-request/${id} `,
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to create service request type.',
        };
      }
    },
    updateNewLeaveType: async (data): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.patch(
          `/app/user/employee/leave-request/${data._id}`,
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to update service request type.',
        };
      }
    },
    deleteLeaveType: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/employee/leave-request/${id}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to delete service request type.',
        };
      }
    },
    readLeaveType: async (
      id,
      rows,
      page,
      sortBy,
      orderBy,
  
      filterValue
    ): Promise<IAPIResponse<{ count: number;leaveCountData: Obj[]; leaveRequests: Obj[] }>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/employee/leave-request/list/${id}?page=${page}&rows=${rows}&sortBy=${sortBy}&orderBy=${orderBy}&filter=${filterValue}`
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to get service request types.',
        };
      }
    },
  };
};
export default employeeService;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  TablePagination,
  Typography,
  useTheme,
} from '@mui/material';
import CardWrapper from '../common/Wrapper/CardWrapper';
import FilterTabComponents from '../common/tabs/FilterTabComponents';
import { Obj } from '../../types/interfaces';
import { user } from '../../service';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { ModalComponent } from '../common/modal/ModalComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAppState } from '../../state/context/AppStateContext';
import parse from 'html-react-parser';
import DeleteIcon from '../icons/DeleteIcon/DeleteIcon';
import ConfirmModal from '../common/modal/ConfirmModal';

const Notification = () => {
  const theme = useTheme();
  const { authStatus,newNotification } = useAppState();
  const [page, setPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [data, setData] = useState<{
    count: number;
    notifications: Obj[];
    notificationCount: number;
  }>({
    count: 0,
    notifications: [],
    notificationCount: 0,
  });
  const tab = [{ title: 'All' }, { title: 'SMS Update' }];
  const [filterValue, setFilterValue] = useState<number>(0);
  const [selected, setSelected] = useState<Obj>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loader,setLoader]=useState(false);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar: snackBar } = useSnackbar();
  const [confirmModalOpen,setConfirmModalOpen]=useState(false); 
  const handleNotification = (rows: number, page: number) => {
    setLoader(true);
    let filter = '';
    switch (filterValue) {
      case 0:
        filter = '';
        break;
      case 1:
        filter = 'smsReport';
        break;
    }

    user.getNotification(rows, page, filter).then((res) => {
      if (!res.status) {
        setData({ count: 0, notifications: [], notificationCount: 0 });
        snackBar(res.message, { variant: 'error' });
        return;
      }
      setLoader(false);
      res.data && setData(res.data);
    });
  };
  const handleCloseConfirmModal = ()=>{
    setConfirmModalOpen(false);
  };
  const handleDeleteNotification = ()=>{
    user.deleteNotification(selected?._id).then((res)=>{
      if (res.status) {
        snackBar(res.message, { variant: 'success' });
        handleNotification(recordsPerPage, page);
        handleCloseConfirmModal();
        return;
      }
      snackBar(res.message, { variant: 'error' });
    });
  };

  useEffect(() => {
    handleNotification(recordsPerPage, page);
  }, [recordsPerPage, page, filterValue,newNotification]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRecordsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardWrapper>
      <Box>
        <Grid container>
          {authStatus?.role !== 'client' && (
            <Grid item sm={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FilterTabComponents
                  tab={tab}
                  setNewDataValue={setFilterValue}
                />
              </Box>
            </Grid>
          )}
          <Grid item sm={12} sx={{  width:'100%'}}>
            {loader? <Box marginTop={3}
              alignItems={'center'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 1,
              }}><CircularProgress /> </Box>:  <Box
              marginTop={3}
              alignItems={'center'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 1,
              
              }}
            
            >
              {data.notifications.map((item, index) => (
                <Paper
                  sx={{
                    width: '100%',
                    p: 2,
                    // minHeight: '70px',
                    minHeight: 'auto',

                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row',lg:'row',xl:'row' },
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 2,
                    }}
                  >
                    <Typography color={'primary'} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Box sx={{':hover':{cursor:'pointer'},  display: 'flex',
                    }}  onClick={(e) => {
                      setSelected(item);
                      handleClick(e);
                    }}>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          width: {
                            xs: '250px',
                            sm: '200px',
                            md: '400px',
                            lg: '500px',
                            xl: '800px',
                          },
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography>
                        {moment(item.createdAt).fromNow()}
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          setSelected(item);
                          handleClick(e);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton 
                        onClick={(e) => {
                          setSelected(item);
                          setConfirmModalOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper>
              ))}
            </Box>}
          </Grid>
          <Grid item sm={12}>
            <Box>
              {data.count > 0 && (
                <TablePagination
                  component='div'
                  count={data.count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={recordsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ModalComponent isClose={handleClose} isOpen={open}>
        <Box>
          <Box sx={{ maxWidth: '818px', maxHeight: '80vh' }}>
            <Box
              sx={{
                width: { xs: '305px', sm: '540px', md: '600px' },
                minWidth: '305px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '63px',
                  borderRadius: '10px',
                  backgroundColor: theme.colors?.primary.default,
                }}
              >
                <Stack direction='row' alignItems='center' gap={1}>
                  <Typography
                    color={'#ffffff'}
                    fontSize='20px'
                    fontWeight={700}
                  >
                    View Notification
                  </Typography>
                </Stack>
              </Box>
              <Box mx={2}>
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='start'
                  spacing={2}
                  marginTop={3}
                >
                  <Typography color='primary' fontWeight={600}>
                    {selected?.title}
                  </Typography>
                  <Typography fontWeight={500}>
                    {selected?.description && parse( selected?.description)}
                  </Typography>
                  <Typography color='grey' fontWeight={500}>
                    {moment(selected?.createdAt).format('llll')} (
                    {moment(selected?.createdAt).fromNow()})
                  </Typography>
                </Stack>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  spacing={2}
                  marginTop={3}
                  mx={2}
                >
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{
                      borderRadius: '9px',
                      height: '50px',
                      backgroundColor: theme.colors?.buttonIcon.default,
                      '&:hover': {
                        backgroundColor: theme.colors?.primary.default,
                      },
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalComponent>
      <ModalComponent isOpen={confirmModalOpen} isClose={handleCloseConfirmModal}>
        <ConfirmModal
          onSubmit={handleDeleteNotification}
          isClose={handleCloseConfirmModal}
          message={'Are you sure you want to delete this notification?'}
        />
      </ModalComponent>
    </CardWrapper>
  );
};

export default Notification;

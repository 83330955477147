import { Avatar, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/logo-full.png';

interface LogoProps {
    sx?: SxProps<Theme>
}
 
const Logo: FC<LogoProps> = ({sx}) => {
  const navigate = useNavigate();

  return ( <Avatar className="cursor-pointer" variant="square" src={logo}  sx={sx} onClick={() => navigate('/')} /> );
};
 
export default Logo;
const processENV = process.env;
const serverURL = processENV.REACT_APP_API_URL || 'http://localhost:4000/api/';
const basePath = processENV.REACT_APP_BASE_URL || '/';

export const KEY_STRING = 'J8JasqoTF925nqMAjiva';

export const apiConfig = {
  baseURL: serverURL,
  timeout: 30000,
  basePath,
};

export const IMAGE_COUNT = 4;

import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as svg } from './RenewalIcon.svg';

const RenewalIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      component={svg}
      viewBox="0 0 26 27"
      sx={{
        padding: '2px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& path': {
          fill: theme.colors?.primary.default,
        },
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default RenewalIcon;

import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as svg } from './NewMessageIcon.svg';

const NewMessageIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      component={svg}
      viewBox='0 0 51 51'
      sx={{
        margin: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& path': {
          fill: theme.colors?.buttonIcon.default,
        },
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default NewMessageIcon;

import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as svg } from './InboxIcon.svg';

const InboxIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon component={svg}  viewBox="0 0 37 36" sx={{
      margin: '0px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...props.sx
    }} {...props} />
  );
};

export default InboxIcon;
import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as svg } from './PenIcon.svg';

const PenIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      component={svg}
      viewBox='0 0 25 25'
      sx={{
        margin: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // '& path': {
        //   fill: theme.colors?.buttonIcon.default
        // },
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default PenIcon;

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardWrapper from '../Wrapper/CardWrapper';
import {
  Button,
  Menu,
  MenuItem,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ITabheading } from '../../../types/interfaces';
import { OrganisationalDetailForm } from '../../../company/company profile/OrganisationalDetailForm';
import DropDownButton from './DropDownButton';
import ErrorIcon from '../../icons/ErrorIcon/ErrorIcon';

interface IVerticalTabs {
  tab: Array<ITabheading>;
  tabPage: any;
  currentPage: any;
  setCurrentpage: any;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const theme = useTheme();

  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: {xs:0.5,sm:3 }}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// const tab: Array<ITabheading> = [
//   { title: 'Organisational Details' },
//   { title: 'Organisational Address' },
//   { title: 'Organisational Size' },
//   { title: 'Licence Details' },
//   { title: 'Sponsored Workers Overview' },
//   { title: 'Organisational Structure' },
// ];

// const tabPage: any = [
//   {
//     page: <OrganisationalDetailForm />,
//   },
// ];
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs: React.FC<IVerticalTabs> = ({
  tab,
  tabPage,
  currentPage,
  setCurrentpage,
}) => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const mobileMod = useMediaQuery('(max-width:900px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentpage(newValue);
  };

  const setValueHandler = () => {
    // setName(item.title);
    // setValue(index);
  };
  return (
    <CardWrapper>
      {mobileMod && <DropDownButton tab={tab} setValue={setCurrentpage} />}
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
        }}
      >
        <Paper
          sx={{
            backgroundColor: '#E5E9EE',
            boxShadow: 'none',

            py: 5,
            borderRadius: '10px',
          }}
        >
          {!mobileMod && (
            <Tabs
              orientation='vertical'
              value={currentPage}
              onChange={handleChange}
              sx={{
                '& .MuiTabs-indicator': { display: 'none' },

                Opacity: '0.9',
                width: '310px',
                minHeight: '65vh',
                pl: 1,
              }}
            >
              {tab.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    label={item.title}
                    icon={item.icon ? <ErrorIcon color='error' /> : ''}
                    iconPosition={'end'}
                    {...a11yProps(index)}
                    sx={{
                      flexDirection: 'row',
                      paddingLeft: 1,

                      border: 'none',
                      color: theme.colors?.primary.default,
                      justifyContent: 'flex-start',
                      background:
                        currentPage == index
                          ? theme.colors?.secondary.default
                          : null,
                      whiteSpace: 'nowrap',
                      borderRadius: '8px 0 0 8px',
                      fontWeight: currentPage == index ? 700 : 500,
                    }}
                  />
                );
              })}
            </Tabs>
          )}
        </Paper>
        <Box
          width={'100vw'}
          minHeight={'80vh'}
          sx={{ minHeight: '50%', p: 0, overflowX: 'scroll' }}
        >
          {tabPage.map((item: any, index: number) => {
            return (
              <TabPanel  key={index} value={currentPage} index={index}>
                {item.page}
              </TabPanel>
            );
          })}
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default VerticalTabs;

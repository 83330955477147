import React, { FC } from 'react';
import Notification from '../components/Notification/Notification';
import Heading from '../components/common/Heading/Heading';
import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface INotificationPage {
  show?: boolean;
}
const NotificationPage: FC<INotificationPage> = ({ show }) => {
  const navigate = useNavigate();
  return (
    <div>
      {!show && (
        <Box display='flex' alignItems='center' gap={1}>
          <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
            <ArrowBack color='primary' />
          </IconButton>
          <Heading>Notifications</Heading>
        </Box>
      )}

      <Notification />
    </div>
  );
};

export default NotificationPage;

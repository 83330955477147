import { Button, styled, useTheme } from '@mui/material';

const CustomButton = styled(Button)((props) => {
  const theme = useTheme();
  return {
    borderRadius: '17px',
    fontWeight: 'bold !important',
    height: '51px',
    width: '80%',
    textTransform: 'uppercase',
    alignItems: 'center',
    color: theme.palette.common.white,
    minWidth: 100,
    '&:hover': {
      backgroundColor: '#465d6e ',
      color: 'white !important',
    },
  };
});

export default CustomButton;

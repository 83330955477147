import React, { FC, ReactNode, useEffect, useState } from 'react';
import { IActiveCompanies, IAuth } from '../../types/interfaces';
import { getAuthStorage, removeAuthStorage, setAuthStorage } from '../../utils';
import { AppStateContext } from '../context/AppStateContext';
import { company, employee } from '../../service';

interface AppStateProviderProps {
  children: ReactNode;
}

const AppStateProvider: FC<AppStateProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<IAuth | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState<string>('');
  const [isComplaint, setIsComplaint] = useState(false);
  const [messageTrigger, SetMessageTrigger] = useState(false);
  const [newInboxCount, setNewInboxCount] = React.useState(0);
  const [isReportObligation, setIsReportObligation] = useState(false);
  const [newNotification, setNewNotification] =
  React.useState<boolean>(false);
  const [companyStatus, setCompanyStatus] = useState({
    authorizationOfficerStatus: 0,
    keyContactStatus: 0,
    legalRepresentativeStatus: 0,
    licenseDetailsStatus: 0,
    organizationAddressStatus: 0,
    organizationDetailsStatus: 0,
    organizationSizeStatus: 0,
    organizationStructureStatus: 0,
    sponsoredWorkersStatus: 0,
    smsUserStatus: [],
  });
  const [permission, setPermission] = useState<any>({
    viewReports: false,
    downloadReports: false,
    createServiceRequest: false,
    createHomeOfficeReportingRequest: false,
    completeMonthlyComplianceChecks: false,
    onboardNewEmployee: false,
    deactivateEmployee: false,
  });
  const [employeeStatus, setEmployeeStatus] = useState({
    contactDetailsStatus: 0,
    keyInformationStatus: 0,
    personalInfoStatus: 0,
    roleDetailsStatus: 0,
    workLocationStatus: 0,
    employeeRecruitment: 0,
    emergencyContactDetailsStatus:0,
    rightToWorkCheckStatus:0,
  });
  const [employeeData, setEmployeeData] = useState({
    _id: '',
    label: '',
    email: '',
  });
  const [dashBoardStatus, setDashBoardStatus] = useState({
    companyDocuments: true,
    companyProfile: true,
    employeeDocuments: true,
    employeeProfile: true,
    employment: true,
    keyPersonal: true,
  });
  const [activeCompanies, setActiveCompanies] = useState<IActiveCompanies[]>(
    []
  );
  const [dashBoardStatusEmployee, setDashBoardStatusEmployee] = useState({
    documents: true,
    employeeProfile: true,
    employment: true,
  });
  const [homeOfficeReportingRequest, setHomeOfficeReportingRequest] =
    useState('');
  const [openSide, SetOpenSide] = React.useState(false);
  const [employeeDeletedStatus,setEmployeeDeletedStatus]=useState(false);
  const [workType, setWorkType] = useState<string>('');
  useEffect(() => {
    const authData = getAuthStorage();

    setUserName(authData?.name ?? '');
    setPermission({ ...authData?.roleSettings });
    setAuth(authData);
    setTimeout(() => {
      setLoading(false);
    });
  }, []);
  const closeSideBar = (): void => {
    SetOpenSide(false);
  };
  const setAuthStatus = (auth?: IAuth): void => {
    if (!auth) {
      removeAuthStorage();
      setAuth(auth);
      return;
    }

    setAuth(() => {
      setAuthStorage(auth);
      return auth;
    });
  };

  const getStatus = () => {
    company.getCompanyStatus(auth?.companyId).then((response) => {
      setCompanyStatus({ ...response.data });
    });
  };
  const getEmployeeStatus = (id = '') => {
    employee.getEmployeeStatus(id).then((response) => {
      setEmployeeStatus(response.data);
    });
  };

  const getCompanyList = async () => {
    try {
      if (!auth?.userId ) {
        return;
      }
      const response = await company.getAccessCompanyList(auth?.userId);
      if (response.status && response.data) {
        if (setActiveCompanies) {
          setActiveCompanies([ ...response.data]);
        }
      }
    } catch (error) {
      console.error('Error fetching company list:', error);
    }
  };

  const providerValue = {
    authStatus: auth,
    setAuthStatus,
    userName,
    companyStatus,
    getStatus,
    getEmployeeStatus,
    employeeStatus,
    isComplaint,
    setIsComplaint,
    permission,
    employeeData,
    setEmployeeData,
    homeOfficeReportingRequest,
    setHomeOfficeReportingRequest,
    isReportObligation,
    setIsReportObligation,
    messageTrigger,
    SetMessageTrigger,
    newInboxCount,
    setNewInboxCount,
    setEmployeeStatus,
    dashBoardStatus,
    setDashBoardStatus,
    dashBoardStatusEmployee,
    setDashBoardStatusEmployee,
    openSide,
    SetOpenSide,
    closeSideBar,
    setUserName,
    employeeDeletedStatus,setEmployeeDeletedStatus,
    newNotification,
    setNewNotification,
    workType, setWorkType,
    activeCompanies,
    setActiveCompanies,
    getCompanyList,
    
  };
  return loading ? (
    <></>
  ) : (
    <AppStateContext.Provider value={providerValue}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;

import React, { useEffect, useState } from 'react';
import {
  Box,
  InputBase,
  Button,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  Grid,
} from '@mui/material';
import FilterTabComponents from '../../common/tabs/FilterTabComponents';
import { ModalComponent } from '../../common/modal/ModalComponent';
import NewMessage from './NewMessage';
import PenIcon from '../../icons/PenIcon/PenIcon';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import { inbox } from '../../../service';
import { IInboxCreate, Obj } from '../../../types/interfaces';
import moment from 'moment';
import { MoreVert } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../../state/context/AppStateContext';
import ConfirmModal from '../../common/modal/ConfirmModal';

const statusTab = [{ title: 'All' }, { title: 'Read' }, { title: 'Unread' }];

const InboxMessage = () => {
  const [data, setData] = useState<{ count: number; inboxMessages: Obj[] }>({
    count: 0,
    inboxMessages: [],
  });
  const { authStatus, setNewInboxCount } = useAppState();
  const [page, setPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState(0);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [replyId, setReplyId] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar: snackBar } = useSnackbar();
  const navigate = useNavigate();
  const [confirmModalOpen,setConfirmModalOpen]=useState(false);  

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCompose = (data: IInboxCreate) => {
    inbox.compose(data).then((res) => {
      if (!res.status) {
        setData({ count: 0, inboxMessages: [] });
        snackBar(res.message, { variant: 'error' });
        return;
      }

      snackBar(res.message, { variant: 'success' });
      closeModal();
    });
  };

  const handleCloseConfirmModal = ()=>{
    setConfirmModalOpen(false);
  };
  const handleDelete = () => {
    inbox.delete(selected).then((res) => {
      if (!res.status) {
        snackBar(res.message, { variant: 'error' });
        return;
      }
      snackBar(res.message, { variant: 'success' });
      handleClose();
      handleCloseConfirmModal();
      handleInbox(recordsPerPage, page);
    }).catch((res)=>{
      snackBar('Something wrong', { variant: 'error' });
    });
  };
  const handleInbox = (rows: number, page: number) => {
    let filter = '';
    switch (filterValue) {
      case 0:
        filter = '';
        break;
      case 1:
        filter = 'isRead';
        break;
      case 2:
        filter = 'isUnRead';
        break;
      default:
        break;
    }
    inbox.messages(rows, page, search, filter).then((res) => {
      if (!res.status) {
        setData({ count: 0, inboxMessages: [] });
        snackBar(res.message, { variant: 'error' });
        return;
      }

      res.data && setData(res.data);
    });
  };

  useEffect(() => {
    handleInbox(recordsPerPage, page);
  }, [filterValue, recordsPerPage, page]);

  const handleUpdateRead = (id: string, replyId: string) => {
    if (replyId === authStatus?.companyId || replyId === authStatus?.userId) {
      inbox.updateRead(id).then((res) => {
        if (!res.status) {
          snackBar(res.message, { variant: 'error' });
          return;
        }

        const tempArr = data.inboxMessages.map((ib: Obj) => {
          if (ib._id === id) return { ...ib, isRead: true };
          else return ib;
        });
        setNewInboxCount &&
          setNewInboxCount((prev) => {
            if (prev !== 0) {
              return prev - 1;
            } else {
              return 0;
            }
          });
        setData({ ...data, inboxMessages: tempArr });
        return;
      });
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRecordsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Box
        component='form'
        display='flex'
        // flexDirection={!mobileMode ? 'row' : 'column'}
        justifyContent={'space-between'}
        gap={1}
        sx={{ mt: { xl: 0, xs: 2 } }}
        onSubmit={(e) => {
          e.preventDefault();
          handleInbox(recordsPerPage, page);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <FilterTabComponents
              tab={statusTab}
              setNewDataValue={setFilterValue}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={4} width={'100%'}>
            <InputBase
              sx={{
                // maxWidth: '400px',
                pl: 3,
                flex: 1,
                backgroundColor: 'rgba(43, 65, 86, 0.08)',
                borderRadius: '9px',
                height: '50px',
                width: '100%',
              }}
              placeholder='Search'
              inputProps={{
                'aria-label': 'Search for users',
              }}
              value={search}
              startAdornment={<SearchIcon sx={{ color: '#B3B3B3' }} />}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
            <Button
              fullWidth
              sx={{ height: '100%', borderRadius: '9px' }}
              startIcon={<PenIcon />}
              variant='contained'
              onClick={() => setIsOpen(true)}
            >
              Compose
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        marginTop={3}
        alignItems={'center'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        {data.inboxMessages.map((item, index) => (
          <Paper
            sx={{
              width: '100%',
              p: 2,
              height: '70px',
            }}
            key={index}
            elevation={
              !item.isRead &&
              (authStatus?.companyId === item.replyTo ||
                authStatus?.userId === item.replyTo)
                ? 2
                : 0
            }
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Typography
                minWidth={'30%'}
                color={'primary'}
                fontWeight={600}
                align='left'
              >
                {item.fromUserName ? item.fromUserName : item.userName}
              </Typography>
              <Typography
                onClick={() => {
                  handleUpdateRead(item._id, item.replyTo);
                  navigate(`/message/${item._id}`);
                }}
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  ':hover': { cursor: 'pointer' },
                }}
                align='left'
                width={{ md: '100%', lg: '40%' }}
              >
                {item.subject}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>
                  {item.date && moment(item.date).format('DD/MM/YYYY')}
                </Typography>
                <IconButton
                  onClick={(e) => {
                    setSelected(item._id);
                    handleClick(e);
                    setReplyId(item.replyTo);
                  }}
                >
                  <MoreVert />
                </IconButton>
              
              </Box>
            </Box>
          </Paper>
        ))}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleUpdateRead(selected, replyId);
              navigate(`/message/${selected}`);
            }}
          >
                    View Message
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleUpdateRead(selected, replyId);
              handleClose();
            }}
          >
                    Mark as read
          </MenuItem>
          <MenuItem onClick={() =>setConfirmModalOpen(true)}>
                    Delete
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        {data.count > 0 && (
          <TablePagination
            component='div'
            count={data.count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={recordsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
      <ModalComponent isOpen={isOpen} isClose={closeModal}>
        <NewMessage onCompose={handleCompose} onClose={closeModal} />
      </ModalComponent>
      <ModalComponent isOpen={confirmModalOpen} isClose={handleCloseConfirmModal}>
        <ConfirmModal
          onSubmit={handleDelete}
          isClose={handleCloseConfirmModal}
          message={'Are you sure you want to delete this message?'}
        />
      </ModalComponent>
    </Box>
  );
};

export default InboxMessage;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as svg } from './Home.svg';

const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox='0 0 37 36'
      component={svg}
      sx={{
        margin: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    />
  );
};

export default HomeIcon;

import React, { FC, SyntheticEvent } from 'react';
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails, AccordionProps, AccordionSummaryProps, styled, Box, Avatar, Typography, useTheme } from '@mui/material';
import { Obj } from '../../../types/interfaces';
import moment from 'moment';
import parse from 'html-react-parser';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: '2px solid #2B415620',
  '&:before': {
    display: 'none',
  },
}));
  
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  textAlign: 'left'
}));

interface MessageAccordionProps {
    data: Obj;
    expanded: number | false;
    onChange: (panel: number) => (event: SyntheticEvent<Element, Event>, newExpanded: boolean) => void;
    index: number;
}

const MessageAccordion: FC<MessageAccordionProps> = ({ data, expanded, onChange, index }) => {
  const theme = useTheme();

  const getName = () => {
    return data.companyName ?? data.userName ?? data.repliedByName ?? '';
  };

  return (
    <Accordion expanded={expanded === index} onChange={onChange(index)}>
      <AccordionSummary>
        <Box sx={{ width: '100%', px: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 3, width: '100%' }}>
            <Box display='flex' alignItems='center' gap={2}>
              <Avatar sx={{ backgroundColor: index % 2 === 0 ? '#B4E5BC' : '#E3C9ED' }}>{getName()[0]}</Avatar>
              <Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='center'>
                <Typography color='primary' fontWeight={600} fontSize={theme.fontSizes.large}>{getName()}</Typography>
                <Typography color='#8E8E8E' fontWeight={600} fontSize={theme.fontSizes.medium} sx={{ display: { xs: 'block', md: 'none' } }} > {data.date ? moment(data.date).format('llll') : ''}</Typography>
              </Box>
            </Box>
            <Typography color='#8E8E8E' fontWeight={600} fontSize={theme.fontSizes.medium} sx={{ display: { xs: 'none', md: 'block' } }} > {data.date ? moment(data.date).format('llll') : ''} ({data.date ? moment(data.date).fromNow() : ''})</Typography>
          </Box>
          {expanded !== index && <Typography
            pl={7}
            fontWeight={500}
            fontSize={theme.fontSizes.large}
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: {
                lg: '850px',
                md: '500px',
                sm: '270px',
                xs: '200px'
              }
            }}
            textAlign='left'
          >
            <div>
              {data.message && parse(data.message)}
            </div>
          </Typography>}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ pl: { xs: 0, sm: 8 }, pr: { xs: 0, sm: 4 } }} py={2}>
          <Typography fontWeight={500} fontSize={theme.fontSizes.large} textAlign='left'>
            { expanded === index &&<div>
              {data.message && parse(data.message)}
            </div>}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MessageAccordion;
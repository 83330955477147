import React, { FC } from 'react';
import CardWrapper from '../components/common/Wrapper/CardWrapper';
import ReadMessage from '../components/message/ReadMessage';

interface MessageProps {}

const Message: FC<MessageProps> = () => {  
  return (
    <CardWrapper>
      <ReadMessage/>
    </CardWrapper>
  );
};

export default Message;
import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as svg } from './PaperIcon.svg';

const PaperIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon component={svg} viewBox="0 0 36 37"  sx={{
      margin: '0px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // '& path': {
      //   fill: theme.colors?.buttonIcon.default
      // },
      ...props.sx
    }} {...props} />
  );
};

export default PaperIcon;

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CardWrapper from '../Wrapper/CardWrapper';
import { useEffect, useState } from 'react';
import { IAuth } from '../../../types/interfaces';
import { useAppState } from '../../../state/context/AppStateContext';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import BroadCastIcon from '../../icons/BroadCastIcon/BroadCastIcon';


/* eslint-disable react/no-unescaped-entities */
interface INotificationDataProps {
  notificationData: {
    title: string;
    message: string;
  };
}
const ModalContent: React.FC<INotificationDataProps> = ({
  notificationData,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { authStatus, setAuthStatus } = useAppState();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    if (authStatus) {
      const newData: IAuth = {
        ...authStatus,
        isBannerNotificationShowed: true,
      };
      setAuthStatus && setAuthStatus({ ...newData });
    }
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        right: 40,
        top: 100,
        width:{xs:'80%',sm:'70%',md:'60%',lg:'45%'},
        zIndex: 3,
        borderRadius: 2,
      }}
    >
      <Collapse in={open}>
        <CardWrapper
          sx={{
            p: 0,
            // border: 'rgba(43, 65, 86, 0.7) solid 1px',
            boxShadow:'9px 7px 30px 4px rgba(43, 65, 86, 0.9)' // Adjust the color and opacity here
          }}
        >
          <div className='modal'>
            <Grid
              container
              gap={0}
              direction={{ xs: 'row',sm:'row' }}
              height={ { xs: '400px', sm:'auto' }}
              minHeight={'60px'}

            >
              <Grid
                item
                xs={12}
                sm={1.5}
               
                lg={1.5}
                minHeight={{ xs: '80px', sm:'80px' }}
                maxHeight={{ xs: '80px', sm:'100%' }}
                alignItems={'center'}
                justifyContent={'flex-start'}
                
              >
                <Box minWidth={{ xs: '100%', sm:'80px' }}
                  width={{ xs: '100%', sm:'80px' }} 
                  minHeight={{ xs: '100%', sm:'80px' }} 
                  height={'100%'}  
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}   bgcolor={theme.colors?.primary.default}  >
                 
                  <BroadCastIcon fontSize='large' />
                
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
              
                display={'flex'}
                alignItems={{ xs: 'center', sm:'center' }}
                justifyContent={{ xs: 'center', sm:'flex-start' }}
                height={ { xs: '250px', sm:'auto' }}
                minHeight={{ xs: '250px', sm:'80px' }}
                width={'100%'}
                sx={{overflowY:'auto',}}
                pl={{xs:0,sm:2}}
              
                // mt={{xs:3,sm:0}}
              >
       
                {/* <Typography>{notificationData.title ?? 'Heading'}</Typography> */}
                <Typography   px={{xs:2,sm:0}} sx={{width:'100%',overflowWrap:'break-word',height:{xs:'100%',sm:'auto'}}}>
                  {notificationData.message ?? 'Message'}
                </Typography>

              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Box width={'100%'}  
                  minHeight={{ xs: '100%', sm:'80px' }} 
                  height={'100%'}   
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}>

                  <IconButton onClick={handleClose}>
                    <HighlightOffRoundedIcon
                      color='error'
                    />
                  </IconButton>
                </Box>

              </Grid>
            </Grid>
          </div>
        </CardWrapper>
      </Collapse>
    </Box>
  );
};
export default ModalContent;

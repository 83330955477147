import { createContext, useContext } from 'react';
import { IAppState } from '../../types/interfaces';

const initialValue = { authStatus: undefined };
export const AppStateContext = createContext<IAppState>(initialValue);

export const useAppState = () => {
  const state = useContext<IAppState>(AppStateContext);
  if (!state) {
    throw new Error('useAppState must be used within AppStateProvider');
  }

  return state;
};

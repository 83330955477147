import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as svg } from './ErrorIcon.svg';

const ErrorIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      component={svg}
      viewBox="0 0 40 40"

      // viewBox='0 0 46 46'
      // viewBox='10 4 30 30'
      sx={{
        margin: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& path': {
          fill: theme.colors?.buttonIcon.default,
        },
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default ErrorIcon;

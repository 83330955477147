import { Button, CircularProgress } from '@mui/material';
import React, { FC, useState } from 'react';

interface ISubmitButton{
    onClick:VoidFunction;
    loader:boolean;
    buttonName?:string;

}
const SubmitButton:FC<ISubmitButton> = ({onClick,loader,buttonName}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (!isClicked) {
      setIsClicked(true);

      // Perform your action here
      setTimeout(() => {
        // Reset the state after some time, e.g., 1 second
        setIsClicked(false);
      }, 1000);
    }
    onClick();
  };
  return (
    <Button
      disabled={isClicked}
      sx={{
        backgroundColor: '#002E51',
        width: '260px',
        height: '60px',
        borderRadius: '17px',
      }}
      variant='contained'
      size='large'
      onClick={handleClick}
    >
      { loader?  <CircularProgress color='secondary' /> :buttonName ?buttonName:'submit'}     
    </Button>
  );
};

export default SubmitButton;
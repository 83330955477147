/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { apiConfig } from '../config/api-config';
import { getAuthStorage, removeAuthStorage } from '../utils';
import authService from './authService';
import companyService from './companyService';
import employeeService from './employeeService';
import home from './homeService';
import inboxService from './inboxService';
import inviteService from './inviteService';
import userService from './userService';
import resourceService from './resourceServices';
import reportService from './reportServices';
import renewalServices from './renewalServices';

const httpClient = axios.create(apiConfig);

httpClient.interceptors.request.use((config: AxiosRequestConfig): any => {
  if (config.headers) {
    config.headers['X-domain-host'] = window.location.hostname || '';
  }
  const auth = getAuthStorage();
  if (auth && config.headers) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }
  return config;
});

export const logout = () => {
  //   clearStorage();
  //   clearSessionStorage();
  removeAuthStorage();
  window.location.href = '/login';
};

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.message === 'Network Error') {
      return;
    }

    if (err.request.responseURL.includes('/api/login')) {
      return;
    }

    if (
      err.response.status === 401 &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      removeAuthStorage();

      logout();
      return;
    }

    if (
      err.response.status === 403 &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      removeAuthStorage();

      logout();
      return;
    }

    throw err;
  }
);

const auth = authService(httpClient);
const invite = inviteService(httpClient);
const company = companyService(httpClient);
const employee = employeeService(httpClient);
const user = userService(httpClient);
const homeService = home(httpClient);
const inbox = inboxService(httpClient);
const resource = resourceService(httpClient);
const report = reportService(httpClient);
const renewal = renewalServices(httpClient);


export { auth, invite, company, employee, user, homeService, inbox , resource,report,renewal};
